import Dexie, { type EntityTable } from 'dexie'
import { type LockImplementation } from './util'
import { rootLog } from './logging'
import { type Logger } from '@andyneville/pino'

let log: Logger | undefined
setTimeout(() => {
  log = rootLog.child({ module: 'db' })
}, 0)

export interface Lock {
  id: string
  locked: boolean
  lockExpiration: number
}

export enum UploadStatus {
  NotStarted = 0,
  Uploaded = 1,
  Verified = 2,
  Error = 3,
}

export interface Syncable {
  localId?: number
  uploadStatus: UploadStatus
  uploadAttempts: number
  uploadStartedAt?: number
  uploadCompletedAt?: number
  uploadVerifiedAt?: Date
}

export interface Recording extends Syncable {
  localId?: number
  id?: string
  tenantId: string
  athleteId: string
  seasonId: string
  tryoutId: string
  createdAt: Date
  uploadStatus: UploadStatus
  uploadAttempts: number
  uploadStartedAt?: number
  uploadCompletedAt?: number
  uploadVerifiedAt?: Date
  // video: Blob
  videoMimeType: string
  // thumbnail: string
  thumbnailMimeType: string
}

export interface RecordingVideo {
  localId: number
  video: Blob
}

export interface RecordingThumbnail {
  localId: number
  thumbnail: string
}

export interface Headshot extends Syncable {
  localId?: number
  id?: string
  tenantId: string
  seasonId: string
  athleteId: string
  createdAt: Date
  uploadStatus: UploadStatus
  uploadAttempts: number
  uploadStartedAt?: number
  uploadCompletedAt?: number
  uploadVerifiedAt?: Date
  // headshot: Blob
  // headshot: ArrayBuffer
  headshotMimeType: string
  headshotUrl?: string
}

export interface HeadshotImage {
  localId: number
  headshot: ArrayBuffer
}

export interface LogEntry {
  localId?: number
  log: string
}

export interface Setting {
  id: string
  boolean?: boolean
  number?: number
  string?: string
}

// export class CheerSyncDexie extends Dexie {
//   recordings!: Table<Recording>

//   constructor () {
//     super('myDatabase')
//     this.version(1).stores({
//       recordings: '++localId,id,athleteId,seasonId,tryoutId,uploadStarted', // Primary key and indexed props
//       locks: 'id'
//     })
//   }
// }

export const db = new Dexie('cheersync3') as Dexie & {
  recordings: EntityTable<Recording, 'localId'>
  recordingVideos: EntityTable<RecordingVideo, 'localId'>
  recordingThumbnails: EntityTable<RecordingThumbnail, 'localId'>
  headshots: EntityTable<Headshot, 'localId'>
  headshotImages: EntityTable<HeadshotImage, 'localId'>
  logs: EntityTable<LogEntry, 'localId'>
  locks: EntityTable<Lock, 'id'>
  settings: EntityTable<Setting, 'id'>
}

// export const db = new CheerSyncDexie()
db.version(1).stores({
  recordings: '++localId,id,athleteId,seasonId,tryoutId', // Primary key and indexed props
  recordingVideos: 'localId',
  recordingThumbnails: 'localId',
  headshots: '++localId,id,athleteId',
  headshotImages: 'localId',
  logs: '++localId',
  locks: 'id',
  settings: '++id'
})

const lockExpiration = 120000 // 2 minutes

export const dbLock = (id: string): LockImplementation => {
  let tempLocked = false
  return {
    get: async (): Promise<boolean> => {
      if (tempLocked) return false
      const now = Date.now()
      tempLocked = true
      const lock = await db.locks.get(id)
      if (lock == null) {
        log?.debug('lock missing')
        await db.locks.add({ id, locked: true, lockExpiration: now + lockExpiration })
        tempLocked = false
        return true
      }

      if (lock.locked && lock.lockExpiration > now) {
        log?.debug('locked, waiting', (lock.lockExpiration - now) / 1000)
        tempLocked = false
        return false
      } else if (lock.locked) {
        log?.debug('lock expired')
      }
      tempLocked = false
      lock.locked = true
      lock.lockExpiration = now + lockExpiration
      await db.locks.put(lock)
      log?.debug('got lock')
      return true
    },
    release: async (): Promise<void> => {
      const lock = await db.locks.get(id)
      if (lock == null) return
      lock.locked = false
      log?.debug('released lock')
      await db.locks.put(lock)
    }
  }
}

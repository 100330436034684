import { Button, Container, Form, GlobalDialogContext, LoadingComponent, PageHeader, SelectBox, classes } from '@andyneville/tailwind-react'
import { type ReactElement, useEffect, useState, useMemo, useContext, useCallback } from 'react'
import AthleteMiniCard from '../components/AthleteMiniCard'
import SortableList, { type DraggableItem } from '../components/SortableList'
import Team, { type ISeasonTeamAthleteWithCrossover } from '../components/Team'
import AthleteSlideout from '../components/AthleteSlideout'
import SortableProvider, { type OnClickListItemFn, type InstanceInfo } from '../components/SortableProvider'
import { useGetAthletesQuery } from '../reducers/apiSlice-athletes'
import { type ISeasonAthleteWithAthlete, type AgeGroup, Position, type SkillLevel, type ITeam, TenantRole, type IStaffAccount } from '../../../api/api'
import { type AgeGridId, ageGridDivisions, ageGroupsByGrid, ageGroupsFromBirthYear, levelsByGrid } from '../../../api/ageGrids'
import AddTeamDialog from '../components/AddTeamDialog'
import { ArrowUpTrayIcon, PlusIcon } from '@heroicons/react/24/outline'
import { useEditTeamMutation, useGetTeamsQuery } from '../reducers/apiSlice-teams'
import EmptyListBox from '../components/EmptyListBox'
import { ListOperation } from '../components/SortableOperation'
import { TenantSeasonContext } from '../components/TenantSeasonProvider'
import { rootLog } from '../logging'
import { type SelectOption } from '@andyneville/tailwind-react/SelectBox'
import ExportDialog from '../components/ExportDialog'
import { rtkErrorToString } from '../util'
import SeasonDialog from '../components/SeasonDialog'

const log = rootLog.child({ module: 'Teams' })

const emptyAthletesArray: ISeasonTeamAthleteWithCrossover[] = []
const emptyTeamsArray: ITeam[] = []
const emptyStaffArray: IStaffAccount[] = []

function teamSortFn (a: ITeam, b: ITeam): number {
  // sort by order
  return a.order - b.order
}

function arrayEqual<T> (a: T[], b: T[]): boolean {
  if (a.length !== b.length) {
    return false
  }
  for (let i = 0; i < a.length; i++) {
    if (a[i] !== b[i]) {
      return false
    }
  }
  return true
}

const levelGroupCss = (enabled: boolean): string => {
  return 'text-gray-600 ' + (enabled ? 'bg-gray-50 ring-gray-600 ring-2' : 'bg-white dark:bg-dark-700 ring-gray-600/20')
}

const positionGroupCss = (enabled: boolean): string => {
  return 'text-gray-600 ' + (enabled ? 'bg-gray-50 ring-gray-600 ring-2' : 'bg-white dark:bg-dark-700 ring-gray-600/20')
}

export default function Teams (): ReactElement {
  const { me, tenant, season, staff = emptyStaffArray, selectedAgeGridOption, setSelectedAgeGridOption } = useContext(TenantSeasonContext)
  const {
    data: athletes = emptyAthletesArray,
    isLoading: athletesLoading,
    error: athletesError
  } = useGetAthletesQuery({ seasonId: season?.id ?? '' }, { skip: season == null })
  const {
    data: allTeams = emptyTeamsArray,
    isLoading: teamsLoading,
    error: teamsError
  } = useGetTeamsQuery({ seasonId: season?.id ?? '' }, { skip: season == null })
  const [allAthletes, setAllAthletes] = useState<ISeasonTeamAthleteWithCrossover[]>(emptyAthletesArray)
  const [crossoverAthletes, setCrossoverAthletes] = useState<ISeasonTeamAthleteWithCrossover[]>(emptyAthletesArray)
  const [rosteredAthletes, setRosteredAthletes] = useState<ISeasonTeamAthleteWithCrossover[]>(emptyAthletesArray)
  // const [teamAthletes, setTeamAthletes] = useState(athletes.sort((a, b) => a.lastName.localeCompare(b.lastName)).slice(0, 4))
  const [showExportDialog, setShowExportDialog] = useState(false)
  const [sortScore, setSortScore] = useState(false)
  const [showAgeGroups, setShowAgeGroups] = useState<Record<string, boolean | undefined>>({} as unknown as Record<string, boolean | undefined>)
  const [showLevels, setShowLevels] = useState<Record<string, boolean | undefined>>({} as unknown as Record<string, boolean | undefined>)
  const [showSeasonDialog, setShowSeasonDialog] = useState(false)
  const [showFlyer, setShowFlyer] = useState(true)
  const [showBase, setShowBase] = useState(true)
  const [showBackSpot, setShowBackSpot] = useState(true)
  const [showTumbler, setShowTumbler] = useState(true)
  const [showAddTeamDialog, setShowAddTeamDialog] = useState(false)
  const [editTeam, { error: editTeamError }] = useEditTeamMutation()
  const globalDialog = useContext(GlobalDialogContext)

  useEffect(() => {
    if (athletesError != null) {
      globalDialog.popupError('Error loading athletes', 'There was an error loading the athletes: ' + rtkErrorToString(athletesError))
      log.error('Error loading athlete', athletesError)
    }
  }, [athletesError, globalDialog])
  useEffect(() => {
    if (teamsError != null) {
      globalDialog.popupError('Error loading teams', 'There was an error loading the teams: ' + rtkErrorToString(teamsError))
      log.error('Error loading teams', teamsError)
    }
  }, [teamsError, globalDialog])
  useEffect(() => {
    if (editTeamError != null) {
      globalDialog.popupError('Error updating team', 'There was an error updating the team: ' + rtkErrorToString(editTeamError))
      log.error('Error editing team', editTeamError)
    }
  }, [editTeamError, globalDialog])

  useEffect(() => {
    if (season == null) {
      setShowSeasonDialog(true)
    }
  }, [season])

  // const sortableContext = useContext(SortableContext)

  const staffOptions = useMemo(() => {
    const result = staff.map((account) => {
      if (account.id === me?.id) {
        return { id: account.id, name: 'My Teams' }
      }
      return { id: account.id, name: `${account.name}'s Teams` }
    })
    result.push({ id: '', name: 'All Teams' })
    return result
  }, [me, staff])

  const [selectedStaff, setSelectedStaff] = useState<SelectOption>(staffOptions.find((account) => account.id === me?.id) ?? staffOptions[0])

  // log.debug('sortableContext', sortableContext)

  const teams = useMemo(() => {
    const ownerId = staff.find((account) => account.roles.includes(TenantRole.Owner))?.id
    const attributedTeams = allTeams.map((team) => { if (team.accountId == null && ownerId != null) { return { ...team, accountId: ownerId } } return team })
    if (selectedStaff.id === '') {
      return attributedTeams
    }
    return attributedTeams.filter((team) => team.accountId === selectedStaff.id)
  }, [allTeams, staff, selectedStaff])

  useEffect(() => {
    if (athletes != null && season != null) {
      setAllAthletes(athletes.map((athlete) =>
        Object.assign({}, athlete, { ageGroups: ageGroupsFromBirthYear(selectedAgeGridOption.id as AgeGridId, athlete.birthYear, athlete.lteMay31, athlete.gteJune1) })
      ).sort((a, b) => a.lastName.localeCompare(b.lastName)))
      updateWindowHeight()
    } else {
      setAllAthletes(athletes)
    }
  }, [athletes, season, selectedAgeGridOption])

  const [selectedAthlete, setSelectedAthlete] = useState<ISeasonAthleteWithAthlete | undefined>()
  const sidebarOpenBinding = useState(false)
  const [, setSidebarOpen] = sidebarOpenBinding

  const updateWindowHeight = (): void => {
    // log.debug('updateWindowHeight')
    const roster = document.getElementById('roster')
    const teams = document.getElementById('teams')
    if (roster != null) {
      if (window.innerWidth < 1024) {
        // @ts-expect-error Max height warning
        roster.style['max-height'] = `${window.innerHeight - 200}px`
        if (teams != null) {
          // @ts-expect-error Max height warning
          teams.style['max-height'] = `${window.innerHeight - 200}px`
        }
      } else {
        // @ts-expect-error Max height warning
        roster.style['max-height'] = `${window.innerHeight - 160}px`
        if (teams != null) {
          // @ts-expect-error Max height warning
          teams.style['max-height'] = `${window.innerHeight - 160}px`
        }
      }
    }
  }

  const ageGroupOptions = useMemo(() => {
    const options = ageGroupsByGrid(selectedAgeGridOption.id as AgeGridId)
    const showGroups = {} as unknown as Record<string, boolean | undefined>
    for (const option of options) {
      // showGroups[option.id as AgeGridId] = true
      showGroups[option.name as AgeGridId] = true
    }
    setShowAgeGroups(showGroups)
    return options
  }, [selectedAgeGridOption])

  const levelOptions = useMemo(() => {
    const levels = levelsByGrid(selectedAgeGridOption.id as AgeGridId)
    const showLevels = {} as unknown as Record<string, boolean | undefined>
    for (const level of levels) {
      // showGroups[option.id as AgeGridId] = true
      showLevels[level] = true
    }
    setShowLevels(showLevels)
    return levels
  }, [selectedAgeGridOption])

  // const showAgeGroup = (id: AgeGridId): boolean => {
  //   return showAgeGroups[id] === true
  // }
  // const toggleShowAgeGroup = (id: AgeGridId): void => {
  //   setShowAgeGroups({ ...showAgeGroups, [id]: showAgeGroups[id] !== true })
  // }

  const showAgeGroup = (id: string): boolean => {
    return showAgeGroups[id] === true
  }
  const toggleShowAgeGroup = (id: string): void => {
    setShowAgeGroups({ ...showAgeGroups, [id]: showAgeGroups[id] !== true })
  }
  const showLevel = (id: string): boolean => {
    return showLevels[id] === true
  }
  const toggleShowLevel = (id: string): void => {
    setShowLevels({ ...showLevels, [id]: showLevels[id] !== true })
  }
  const selectableAthletes = useMemo(() => {
    // log.debug('useMemo 1', rosteredAthletes.length)
    if (season == null || allAthletes.length === 0) {
      return emptyAthletesArray
    }
    const combinedAthletes = [...crossoverAthletes, ...allAthletes]
    const showAllAgeGroups = Object.values(showAgeGroups).every((v) => v)
    const showAllLevels = Object.values(showLevels).every((v) => v)
    const newRosterAthletes = combinedAthletes.filter((athlete) => {
      if (showAllAgeGroups) return true
      for (const [ageGroupId, show] of Object.entries(showAgeGroups)) {
        if (show === true && ((athlete.ageGroups?.includes(ageGroupId as AgeGroup)) ?? false)) return true
      }
      return false
    }).filter((athlete) => {
      if (showAllLevels) return true
      for (const [levelId, show] of Object.entries(showLevels)) {
        if (show === true && ((athlete.levels?.includes(levelId as SkillLevel)) ?? false)) return true
      }
      return false
    }).filter((athlete) => {
      if (showFlyer && showBase && showBackSpot && showTumbler) return true
      if (showFlyer && ((athlete.positions?.includes(Position.Flyer)) ?? false)) return true
      if (showBase && ((athlete.positions?.includes(Position.Base)) ?? false)) return true
      if (showBackSpot && ((athlete.positions?.includes(Position.Backspot)) ?? false)) return true
      if (showTumbler && ((athlete.positions?.includes(Position.Tumbler)) ?? false)) return true
      return false
    }).filter((athlete) => {
      if (athlete.crossover !== true && rosteredAthletes.find((a) => a.id === athlete.id) != null) return false
      return true
    })
    if (sortScore) {
      return newRosterAthletes.sort((a, b) => (b.totalRating ?? 0) - (a.totalRating ?? 0))
    } else {
      return newRosterAthletes.sort((a, b) => a.lastName.localeCompare(b.lastName))
    }
    // log.debug('filteredAthletes', filteredAthletes)
  }, [crossoverAthletes, allAthletes, rosteredAthletes, season, showAgeGroups, sortScore, showLevels, showFlyer, showBase, showBackSpot, showTumbler])
  // eslint -disable-line react-hooks/exhaustive-deps

  const addCrossover = (athlete: ISeasonAthleteWithAthlete): void => {
    // log.debug('addCrossover', athlete, crossoverAthletes)
    if (crossoverAthletes.find((a) => a.id === athlete.id) == null) {
      const newCrossoverAthletes = [...crossoverAthletes, { ...athlete, crossover: true }]
      log.debug('added crossover', newCrossoverAthletes)
      setCrossoverAthletes(newCrossoverAthletes)
    }
  }

  useEffect(() => {
    // log.debug('useEffect 2')
    /*
    const teams = document.querySelectorAll('.team')
    log.debug('calling sortable')
    const sortable = new Sortable(teams, {
      draggable: '.draggable',
      mirror: {
        constrainDimensions: true,
      },
      plugins: [Plugins.ResizeMirror],
    })
    log.debug('sortable', sortable)
*/
    updateWindowHeight()
    window.addEventListener('resize', updateWindowHeight)
    // Cleanup on component unmount
    return () => {
      window.removeEventListener('resize', updateWindowHeight)
    }
  }, []) // Empty dependency array ensures this effect runs once on mount and cleanup on unmount

  const onClick = (_: string, athlete: ISeasonAthleteWithAthlete): void => {
    setSelectedAthlete(athlete); setSidebarOpen(true)
  }

  const setRosterItems = (oldItems: ISeasonAthleteWithAthlete[], newItems: ISeasonAthleteWithAthlete[], itemId: string, fromListId: string, toListId: string, operation: ListOperation): void => {
    log.debug('update roster list')
    if (operation === ListOperation.Reorder) {
      log.debug('Roster reordering athlete (noop)')
      // currently a noop
    } else if (operation === ListOperation.Add) {
      const addedItems = newItems.filter((newItem) => !oldItems.some((oldItem) => oldItem.id === newItem.id))
      if (addedItems.length === 0) {
        log.error('WARNING: Roster - No items added')
      } else if (addedItems.length > 1) {
        log.error('WARNING: Roster - More than one item added', addedItems)
      } else if (addedItems[0].id !== itemId) {
        log.error('WARNING: Roster - Wrong item added', addedItems[0], itemId)
      } else {
        log.debug('Adding athlete to roster', addedItems[0].id, 'from', fromListId)
      }
    } else if (operation === ListOperation.Remove) {
      const removedItems = oldItems.filter((oldItem) => !newItems.some((newItem) => oldItem.id === newItem.id))
      if (removedItems.length === 0) {
        log.error('WARNING: Roster - No items removed')
      } else if (removedItems.length > 1) {
        log.error('WARNING: Roster - More than one item removed', removedItems)
      } else if (removedItems[0].id !== itemId) {
        log.error('WARNING: Roster - Wrong item removed', removedItems[0], itemId)
      } else {
        log.debug('Removing athlete from roster', removedItems[0])
        log.debug('Removing athlete from roster', removedItems[0].id, 'moving to new team/pos', toListId)
        if ((removedItems[0] as ISeasonTeamAthleteWithCrossover).crossover === true) {
          const newCrossoverAthletes = crossoverAthletes.filter((athlete) => athlete.id !== removedItems[0].id)
          setCrossoverAthletes(newCrossoverAthletes)
        }
      }
    } else {
      log.error('Unknown operation', operation)
    }
    /* const newRosteredAthleteIds: string[] = []
    for (const listId in instance.lists) {
      if (listId === 'roster') {
        continue
      }
      const list = instance.lists[listId]
      for (const item of list.items) {
        newRosteredAthleteIds.push(item.id)
      }
    }
    if (newRosteredAthleteIds.length !== athletes.length) {
      // log.debug('Updating rostered athletes', athletes.length, newRosteredAthleteIds.length)
      // setRosteredAthletes(athletes.filter((athlete) => !newRosteredAthleteIds.includes(athlete.id)))
    } */
  }

  const listUpdate = useCallback((instance: InstanceInfo): void => {
    // log.debug('listUpdate')
    const newRosteredAthleteIds: string[] = []
    for (const listId in instance.lists) {
      if (listId === 'roster') {
        continue
      }
      const list = instance.lists[listId]
      // log.debug('list', listId, list.items.length)
      for (const item of list.items) {
        newRosteredAthleteIds.push(item.id)
      }
    }
    // log.debug('Compare rostered athletes', athletes.length, rosteredAthletes.length, newRosteredAthleteIds.length)
    if (newRosteredAthleteIds.length !== rosteredAthletes.length) {
      // log.debug('Updating rostered athletes', rosteredAthletes.length, newRosteredAthleteIds.length)
      const newRosteredAthletes = athletes.filter((athlete) => newRosteredAthleteIds.includes(athlete.id))
      if (!arrayEqual(rosteredAthletes, newRosteredAthletes)) {
        setRosteredAthletes(newRosteredAthletes)
      }
    }
  }, [athletes, rosteredAthletes])

  const sortedTeams = useMemo(() => {
    return teams.slice().sort(teamSortFn)
  }, [teams])

  const sortTeamUp = (team: ITeam, index: number): void => {
    if (index === 0) {
      return
    }
    // const currentOrder = team.order ?? 0
    let newOrder = 0
    if (index === 1) {
      const aboveOrder = sortedTeams[index - 1].order ?? 0
      newOrder = aboveOrder - 1
    } else {
      const aboveOrder1 = sortedTeams[index - 1].order ?? 0
      const aboveOrder2 = sortedTeams[index - 2].order ?? 0
      newOrder = (aboveOrder1 + aboveOrder2) / 2
    }
    const doUpdate = async (): Promise<void> => {
      // log.debug('Updating team order', team.id, currentOrder, newOrder)
      await editTeam({ teamId: team.id, seasonId: season?.id ?? '', order: newOrder })
    }
    void doUpdate()
  }

  const sortTeamDown = (team: ITeam, index: number): void => {
    if (index === sortedTeams.length - 1) {
      return
    }
    // const currentOrder = team.order ?? 0
    let newOrder = 0
    if (index === sortedTeams.length - 2) {
      const belowOrder = sortedTeams[index + 1].order ?? 0
      newOrder = belowOrder + 1
    } else {
      const belowOrder1 = sortedTeams[index + 1].order ?? 0
      const belowOrder2 = sortedTeams[index + 2].order ?? 0
      newOrder = (belowOrder2 + belowOrder1) / 2
    }
    const doUpdate = async (): Promise<void> => {
      // log.debug('Updating team order', team.id, currentOrder, newOrder)
      await editTeam({ teamId: team.id, seasonId: season?.id ?? '', order: newOrder })
    }
    void doUpdate()
  }

  const onSetItems = useCallback((items: DraggableItem[], itemId: string, fromListId: string, toListId: string, operation: ListOperation) => {
    setRosterItems(selectableAthletes, items as unknown as ISeasonAthleteWithAthlete[], itemId, fromListId, toListId, operation)
  }, [selectableAthletes]) // TODO: are we intentionally not adding setRosterItems?

  const onUpdate = useCallback((instance: InstanceInfo) => {
    listUpdate(instance)
  }, [listUpdate])

  const onExportClose = (updated?: boolean): void => {
    log.debug('onImportClose', updated)
    setShowExportDialog(false)
  }

  return (
    <div id='teamHome'>
      <AthleteSlideout athlete={selectedAthlete} sidebarOpenBinding={sidebarOpenBinding} />
      <SeasonDialog open={showSeasonDialog} onClose={() => { setShowSeasonDialog(false) }} />
      <ExportDialog open={showExportDialog} onClose={onExportClose} />
      <AddTeamDialog open={showAddTeamDialog} seasonId={season?.id ?? ''} onClose={() => { setShowAddTeamDialog(false) }} />
      <PageHeader title={'Create Teams: ' + (season?.name ?? 'No Season Selected')} subtitle={tenant?.name}>
        {season != null &&
          <>
            <Form>
              <SelectBox columns={12} options={staffOptions} value={selectedStaff} onChange={(option) => { if (option != null) setSelectedStaff(option) }} />
            </Form>
            {season != null &&
              <>
                <Button className='ml-4' Icon={PlusIcon} primary label='Add Team' onClick={() => { setShowAddTeamDialog(true) }} />
                {sortedTeams.length > 0 &&
                  <Button className='ml-4' Icon={ArrowUpTrayIcon} label='Export' onClick={() => { setShowExportDialog(true) }} />
                }
              </>
            }
          </>
        }
      </PageHeader>
      <LoadingComponent isLoading={season == null || athletesLoading || teamsLoading}>
        <div className="flex-row flex-wrap items-center mb-2 sm:space-x-4 sm:flex">
          <div className='flex flex-row items-center mb-2 space-x-2 sm:flex-grow'>
            <div className='text-sm'>Sort:</div>
            <button className={classes('inline-flex flex-shrink-0 items-center rounded-full px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset', positionGroupCss(!sortScore))} onClick={() => { setSortScore(false) }}>Name</button>
            <button className={classes('inline-flex flex-shrink-0 items-center rounded-full px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset', positionGroupCss(sortScore))} onClick={() => { setSortScore(true) }}>Score</button>
          </div>

          <div className='flex flex-row items-center mb-2 sm:flex-grow'>
            <div className='text-sm'>Age Grid:</div>
            <SelectBox outerClassName='w-96' className='!ring-0' options={ageGridDivisions as unknown as SelectOption[]} value={selectedAgeGridOption} onChange={setSelectedAgeGridOption} />
          </div>

          <div className='flex flex-row flex-wrap items-center mb-2 space-x-2 sm:flex-grow'>
            <div className='text-sm'>Age Groups:</div>
            {ageGroupOptions.map((option) => (
              <button key={option.id} className={classes('inline-flex flex-shrink-0 items-center rounded-full px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset', levelGroupCss(showAgeGroup(option.name)))} onClick={() => { toggleShowAgeGroup(option.name) }}>{option.name}</button>
            ))}
          </div>

          <div className='flex flex-row items-center mb-2 space-x-2 sm:flex-grow'>
            <div>Levels:</div>
            {levelOptions.map((option) => (
              <button key={option} className={classes('inline-flex flex-shrink-0 items-center rounded-full px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset', levelGroupCss(showLevel(option as string)))} onClick={() => { toggleShowLevel(option as string) }}>{option}</button>
            ))}
          </div>

          <div className='flex flex-row items-center mb-2 space-x-2'>
            <div>Positions:</div>
            <button className={classes('inline-flex flex-shrink-0 items-center rounded-full px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset', positionGroupCss(showFlyer))} onClick={() => { setShowFlyer(!showFlyer) }}>Flyer</button>
            <button className={classes('inline-flex flex-shrink-0 items-center rounded-full px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset', positionGroupCss(showBase))} onClick={() => { setShowBase(!showBase) }}>Base</button>
            <button className={classes('inline-flex flex-shrink-0 items-center rounded-full px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset', positionGroupCss(showBackSpot))} onClick={() => { setShowBackSpot(!showBackSpot) }}>Backspot</button>
            <button className={classes('inline-flex flex-shrink-0 items-center rounded-full px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset', positionGroupCss(showTumbler))} onClick={() => { setShowTumbler(!showTumbler) }}>Tumbler</button>
          </div>
        </div>
        <SortableProvider rootSelector='#teamHome' addMirrorClass='!bg-green-200/20 dark:!bg-green-400/60 mirror-123' addSourceClass='!bg-green-200 dark:!bg-dark-400' handleSelector='.drag-target' onClick={onClick as unknown as OnClickListItemFn}>
          <Container>
            <div id='roster' className='overflow-auto sm:col-span-3 overscroll-y-contain'>
              <SortableList id='roster' items={selectableAthletes as unknown as DraggableItem[]} setItems={onSetItems} update={onUpdate} className="grid grid-cols-1 gap-3 sm:min-w-[250px]">
                {athletes.length === 0 &&
                  <EmptyListBox name='athlete' hideArrow text='Athletes must be added on the Roster tab.' />
                }
                {athletes.length > 0 && selectableAthletes.length === 0 &&
                  <EmptyListBox name='athlete' hideArrow text='All athletes have been placed. Additional athletes may be added on the Roster tab.' />
                }
                {selectableAthletes.map((athlete) => (
                  <AthleteMiniCard
                    key={athlete.id}
                    athlete={athlete}
                    showAgeGroups={showAgeGroups}
                    editable={true}
                    canCrossover={false}
                    crossover={athlete.crossover}
                  />
                ))}
              </SortableList>
            </div>
            <div id='teams' className='overflow-auto sm:col-span-9 overscroll-y-contain'>
              {teams.length === 0 &&
                <EmptyListBox name='team' />
              }
              {sortedTeams.map((team, idx) => (
                <Team
                  key={team.id}
                  editable={team.accountId === me?.id}
                  ownership={team.accountId === me?.id ? 'My Team' : (staff.find((account) => account.id === team.accountId)?.name ?? 'Unknown') + "'s Team"}
                  name={team.name}
                  seasonId={season?.id ?? ''}
                  athletes={athletes}
                  showAgeGroups={showAgeGroups}
                  id={team.id}
                  onSortUp={() => { sortTeamUp(team, idx) }}
                  onSortDown={() => { sortTeamDown(team, idx) }}
                  hideUp={idx === 0}
                  hideDown={idx === sortedTeams.length - 1}
                  onAddCrossover={addCrossover}
                />
              ))}
            </div>
          </Container>
        </SortableProvider>
      </LoadingComponent>
    </div>
  )
}

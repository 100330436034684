import { type ThunkDispatch, type UnknownAction, createSlice } from '@reduxjs/toolkit'
import { decode } from '../jwt-decode'
import { cacheParallel } from '../util'
import { AccountRole, type TenantRole, tenantPlans, type ITenant, type TenantPlanInfo, type AuthResult } from '../../../api/api'
import { rootLog } from '../logging'

const log = rootLog.child({ module: 'authReducer' })

log.debug('authReducer.ts: start')

let apiRootUrl: string = ''
export function setApiRootUrl (url: string): void {
  apiRootUrl = url
}

let googleAuthUri: string = ''
export function setGoogleAuthUri (uri: string): void {
  googleAuthUri = uri
}

let googleClientId: string = ''
export function setGoogleClientId (clientId: string): void {
  googleClientId = clientId
}

export interface AuthStateType {
  authState: AuthState
  accessToken: string | null
  refreshTokenExpiresAt: number
  refreshToken: string | null
  tokenExpirationTime: number
  authenticated: boolean
  email: string | null
  firstName: string | null
  lastName: string | null
  name: string | null
  pictureUrl: string | null
  roles: Array<AccountRole | TenantRole>
  isAdmin: boolean
  redirectUrl: string | null
  currentTenantId: string | null
  currentTenant: ITenant | null
  currentPlanInfo: TenantPlanInfo | null
  webVersion: string | null
  tenants: ITenant[]
}

type DispatchType = ThunkDispatch<{ auth: AuthStateType }, void, UnknownAction>
type GetStateFnType = () => { auth: AuthStateType }

const MAX_TIMEOUT = 2147483647

export interface OAuthProviderConfig {
  authUri: () => string
  params: Record<string, string | (() => string)>
}
const oAuthProviders: Record<string, OAuthProviderConfig> = {
  google: {
    authUri: () => googleAuthUri,
    params: {
      scope: 'email profile openid',
      access_type: 'offline',
      response_type: 'code',
      client_id: () => googleClientId
    }
  }
}

export enum AuthState {
  Unauthenticated,
  Loading,
  TemporarilyUnauthenticated,
  Authenticated,
  AuthenticatedNeedsTenantChoice,
  SelfRegistrationDisabled
}

interface PersistedProfile {
  authenticated: boolean
  email: string
  name: string
  firstName: string
  lastName: string
  pictureUrl: string
  roles: Array<AccountRole | TenantRole>
  isAdmin: boolean
  currentTenantId: string | null
  currentTenant: ITenant | null
  currentPlanInfo: TenantPlanInfo | null
  tenants: ITenant[]
}

let lastTenantId
try {
  lastTenantId = localStorage.getItem('tenant')
} catch { }

const persistedProfileStorage = localStorage.getItem('profile')
let persistedProfile: PersistedProfile = {} as unknown as PersistedProfile
if (persistedProfileStorage !== null) {
  log.debug('app load init: loading persisted profile')
  try {
    persistedProfile = JSON.parse(persistedProfileStorage)
    log.debug('app load init: loaded persisted profile', persistedProfile)
    if (lastTenantId != null) {
      if (persistedProfile.currentTenantId == null) {
        persistedProfile.currentTenantId = lastTenantId
      } else if (persistedProfile.currentTenantId !== lastTenantId) {
        log.debug('app load init: clearing last tenant')
        persistedProfile.currentTenantId = null
        persistedProfile.currentTenant = null
      }
    }
  } catch (e) {
    log.error('app load init: error parsing persisted profile')
    log.error(e)
  }
}

let initialRefreshTokenExpiresAt: number = 0
let initialRefreshToken: string | null = null
try {
  log.debug('app load init: Looking for persisted refresh token...')
  const initialRefreshTokenExpiresAtStr = localStorage.getItem('refreshTokenExpiresAt')
  if (initialRefreshTokenExpiresAtStr !== null && initialRefreshTokenExpiresAtStr.length > 0) {
    initialRefreshTokenExpiresAt = parseInt(initialRefreshTokenExpiresAtStr, 10)
    log.debug(`app load init: Persisted refresh token valid until ${new Date(initialRefreshTokenExpiresAt).toString()}`)
  }
  initialRefreshToken = localStorage.getItem('refreshToken')
} catch {
  initialRefreshTokenExpiresAt = 0
}

const initialNow = Date.now()

let initialAuthState: AuthState = AuthState.Unauthenticated
if (window.location.pathname.startsWith('/auth/')) {
  initialAuthState = AuthState.Loading
} else if (persistedProfile.authenticated && initialRefreshToken != null && initialRefreshToken.length > 10 && initialRefreshTokenExpiresAt !== null && initialRefreshTokenExpiresAt > initialNow) {
  initialAuthState = AuthState.Authenticated
} else {
  log.debug('initialAuthState uninitialized', persistedProfile.authenticated, initialRefreshToken, initialRefreshTokenExpiresAt, initialNow)
}

log.debug('initialAuthState', initialAuthState)

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    authState: initialAuthState as AuthState,
    accessToken: null as string | null,
    refreshTokenExpiresAt: (persistedProfile.authenticated && initialRefreshTokenExpiresAt !== null && initialRefreshTokenExpiresAt > initialNow) ? initialRefreshTokenExpiresAt : 0,
    refreshToken: initialRefreshToken,
    tokenExpirationTime: 0,
    authenticated: persistedProfile?.authenticated != null ? persistedProfile.authenticated : false,
    email: persistedProfile?.email != null ? persistedProfile.email : null,
    firstName: persistedProfile?.firstName != null ? persistedProfile.firstName : null,
    lastName: persistedProfile?.lastName != null ? persistedProfile.lastName : null,
    name: persistedProfile?.name != null ? persistedProfile.name : null,
    pictureUrl: persistedProfile?.pictureUrl != null ? persistedProfile.pictureUrl : null,
    roles: persistedProfile?.roles != null ? persistedProfile.roles : [],
    isAdmin: persistedProfile?.isAdmin != null ? persistedProfile.isAdmin : false,
    redirectUrl: null as string | null,
    currentTenantId: persistedProfile?.currentTenantId != null ? persistedProfile.currentTenantId : null,
    currentTenant: persistedProfile?.currentTenant != null ? persistedProfile.currentTenant : null,
    currentPlanInfo: persistedProfile?.currentPlanInfo != null ? persistedProfile.currentPlanInfo : null,
    webVersion: null as string | null,
    tenants: persistedProfile?.tenants != null ? persistedProfile.tenants : []
  } satisfies AuthStateType,
  reducers: {
    authenticated (state, action) {
      log.debug('Set authenticated...')
      const { refreshTokenExpiresAt, refreshToken, accessToken, idToken, tokenExpirationTime, redirectUrl = null, currentTenant = null, tenants = [], webVersion } = action.payload as { refreshTokenExpiresAt: number, refreshToken: string, accessToken: string, idToken: string, tokenExpirationTime: number, redirectUrl: string, currentTenant: ITenant | null, tenants: ITenant[], webVersion: string | null }
      const currentTenantId = currentTenant?.id ?? null
      const currentPlanInfo = currentTenant?.plan != null ? tenantPlans[currentTenant.plan] : null
      state.authState = AuthState.Authenticated
      state.authenticated = true
      const { email, given_name: firstName, family_name: lastName, picture: pictureUrl, name, roles: rolesStr } = decode(idToken) as { email: string | null, given_name: string | null, family_name: string | null, picture: string | null, name: string | null, roles: string | null }
      const roles = rolesStr?.split(',') as Array<AccountRole | TenantRole> ?? []
      state.roles = roles
      const isAdmin = roles.includes(AccountRole.SuperAdministrator) || state.roles.includes(AccountRole.Administrator)
      state.accessToken = accessToken
      state.refreshTokenExpiresAt = refreshTokenExpiresAt
      state.refreshToken = refreshToken
      state.tokenExpirationTime = tokenExpirationTime
      state.authenticated = true
      state.redirectUrl = redirectUrl
      state.email = email
      state.firstName = firstName
      state.lastName = lastName
      state.name = name != null && name.length > 0 ? name : firstName != null ? `${firstName} ${lastName}` : null
      state.pictureUrl = pictureUrl != null && pictureUrl.length > 0 ? pictureUrl : null
      state.isAdmin = isAdmin
      state.currentTenantId = currentTenantId
      state.currentTenant = currentTenant
      state.currentPlanInfo = currentPlanInfo
      state.webVersion = webVersion
      state.tenants = tenants
      if (state.tenants != null && state.tenants.length > 1 && state.currentTenant == null) {
        // needs tenant choice
        state.authState = AuthState.AuthenticatedNeedsTenantChoice
        log.debug('Set authenticatedNeedsTenantChoice...')
      }
      try {
        log.debug('storing persisted profile (authenticated)', JSON.stringify({ authenticated: state.authenticated, email, name, firstName, lastName, pictureUrl, roles, isAdmin, currentTenantId, currentTenant, currentPlanInfo }))
        localStorage.setItem('profile', JSON.stringify({ authenticated: state.authenticated, email, name, firstName, lastName, pictureUrl, roles, isAdmin, currentTenantId, currentTenant, currentPlanInfo }))
        if (refreshTokenExpiresAt != null && refreshTokenExpiresAt > 0) {
          log.debug('storing persisted refreshTokenExpiresAt & refreshToken', refreshTokenExpiresAt, refreshToken)
          localStorage.setItem('refreshTokenExpiresAt', refreshTokenExpiresAt.toString())
          if (refreshToken != null) {
            log.debug('storing persisted refreshToken', refreshToken)
            localStorage.setItem('refreshToken', refreshToken.toString())
          }
        }
        if (state.currentTenant != null) {
          log.debug('storing persisted tenant id', state.currentTenant.id)
          localStorage.setItem('tenant', state.currentTenant.id)
        } else {
          localStorage.removeItem('tenant')
        }
      } catch (e) {
        log.error('Error persisting profile')
      }
    },
    loading (state) {
      log.debug('Set loading...')
      state.authState = AuthState.Loading
    },
    temporarilyUnauthenticated (state, action) {
      log.debug('Set temporarily unauthenticated...')
      state.authState = AuthState.TemporarilyUnauthenticated
      const { redirectUrl = null } = action.payload
      state.redirectUrl = redirectUrl
    },
    unauthenticated (state, action) {
      log.debug('Set unauthenticated...')
      const { redirectUrl = null } = action.payload
      state.authState = AuthState.Unauthenticated
      state.authenticated = false
      state.accessToken = null
      state.refreshTokenExpiresAt = 0
      state.refreshToken = null
      state.roles = []
      state.isAdmin = false
      state.email = null
      state.firstName = null
      state.lastName = null
      state.name = null
      state.pictureUrl = null
      state.redirectUrl = redirectUrl
      state.currentTenantId = null
      state.currentTenant = null
      state.currentPlanInfo = null
      state.tenants = []
      state.webVersion = null
      // log.debug('storing persisted profile (unauthenticated)')
      // localStorage.setItem('profile', JSON.stringify({ authenticated: false }))
    },
    selfRegistrationDisabled (state) {
      log.debug('Set selfRegistrationDisabled...')
      state.authState = AuthState.SelfRegistrationDisabled
      state.authenticated = false
      state.accessToken = null
      state.refreshTokenExpiresAt = 0
      state.refreshToken = null
      state.roles = []
      state.isAdmin = false
      state.email = null
      state.firstName = null
      state.lastName = null
      state.name = null
      state.pictureUrl = null
      state.currentTenantId = null
      state.currentTenant = null
      state.currentPlanInfo = null
      state.tenants = []
      log.debug('storing persisted profile (unauthenticated)')
      try {
        localStorage.setItem('profile', JSON.stringify({ authenticated: false }))
      } catch (e) {
        log.error('Error storing profile')
        log.error(e)
      }
    }
  }
})

const { actions, reducer } = authSlice
export const { authenticated, unauthenticated, loading, temporarilyUnauthenticated, selfRegistrationDisabled } = actions
export default reducer

type ThunkFunction<TReturn> = (dispatch: DispatchType, getState: GetStateFnType) => Promise<TReturn>

let refreshTokenMonitor: number | undefined
function monitorRefreshToken (refreshTokenExpiresAt: number): void {
  if (refreshTokenExpiresAt === 0) {
    return
  }
  const now = Date.now()
  const millisecondsToExpiration = refreshTokenExpiresAt - now
  if (refreshTokenMonitor != null) {
    window.clearTimeout(refreshTokenMonitor)
  }
  if (millisecondsToExpiration > MAX_TIMEOUT) {
    log.debug(`Refresh token expires in ${Math.floor(millisecondsToExpiration / (1000 * 60 * 60 * 24))} days, not monitoring expiration`)
    return
  }
  refreshTokenMonitor = window.setTimeout(() => {
    log.debug('refresh token expired')
    refreshTokenMonitor = undefined
  }, millisecondsToExpiration)
  log.debug(`Monitoring refresh token, expires at ${new Date(refreshTokenExpiresAt).toString()}`, millisecondsToExpiration, refreshTokenExpiresAt, now)
}

export function tokenExpired (token: string): boolean {
  if (token == null || token.length < 10) {
    return false
  }
  const decodedToken = decode(token)
  const { exp } = decodedToken
  const now = Date.now()
  const millisecondsToExpiration = ((exp ?? 0) * 1000 - now)
  return millisecondsToExpiration < 0
}

let accessTokenMonitor: number | undefined
function monitorAccessToken (accessToken: string): void {
  if (accessToken == null || accessToken.length < 10) {
    return
  }
  const decodedToken = decode(accessToken)
  const { exp } = decodedToken
  const now = Date.now()
  const millisecondsToExpiration = ((exp ?? 0) * 1000 - now)
  if (accessTokenMonitor != null) {
    window.clearTimeout(accessTokenMonitor)
  }
  accessTokenMonitor = window.setTimeout(() => {
    log.debug('access token expired')
    accessTokenMonitor = undefined
  }, millisecondsToExpiration)
  log.debug(`Monitoring access token, expires in ${(millisecondsToExpiration / 1000).toFixed(0)} seconds`)
}

function applyAuthResult (dispatch: DispatchType, auth: AuthStateType, authResult: AuthResult, redirectUrl?: string): void {
  const { ExpiresIn: expiration = 30000, AccessToken: accessToken, IdToken: idToken, RefreshToken: refreshToken = auth.refreshToken, RefreshExpiresAt: refreshTokenExpiresAt = auth.refreshTokenExpiresAt, Tenant: currentTenantId, Tenants: tenants, WebVersion: webVersion } = authResult
  if (accessToken != null) monitorAccessToken(accessToken)
  if (refreshTokenExpiresAt != null) monitorRefreshToken(refreshTokenExpiresAt)
  const now = Date.now()
  const gracePeriodSeconds = 5
  const tokenExpirationTime = now + (expiration * 1000) - gracePeriodSeconds
  let currentTenant: ITenant | undefined
  if (tenants != null) {
    currentTenant = tenants.find((t: ITenant) => t.id === currentTenantId)
  }
  log.info('Successfully authenticated!', refreshTokenExpiresAt, authResult)
  if (redirectUrl != null && redirectUrl.length > 0) {
    if (redirectUrl.includes('/logout')) {
      log.debug(`rewriting redirect from ${redirectUrl} to /`)
      redirectUrl = '/'
    }
    log.debug('set redirectUrl:', redirectUrl)
  }
  dispatch(authenticated({ accessToken, idToken, refreshTokenExpiresAt, refreshToken, tokenExpirationTime, redirectUrl, currentTenant, tenants, webVersion }))
}

async function internalDoTokenRefresh (dispatch: DispatchType, getState: GetStateFnType, tenantId?: string, refreshToken?: string): Promise<string | null> {
  log.debug('Attempting to refresh token...')
  const { auth } = getState()
  if (refreshToken == null && auth.refreshToken == null) {
    log.warn('Refresh token is null, quitting')
    return null
  }
  if (tenantId == null) {
    tenantId = auth.currentTenantId ?? undefined
  }
  log.debug('Attempting to refresh token...', tenantId)

  try {
    const response = await fetch(`${apiRootUrl}/auth/refresh?refreshToken=${encodeURIComponent(refreshToken ?? auth.refreshToken ?? '')}${tenantId != null ? `&tenantId=${tenantId}` : ''}`, {
      method: 'POST'
    })
    if (response.ok) {
      log.debug('Renew token succeeded, updating details')
      const result = await response.json() as AuthResult
      applyAuthResult(dispatch, auth, result)
      return result.AccessToken ?? null
    } else {
      log.warn('Renew token failed!')
      log.warn(response)
      return null
    }
  } catch (e) {
    log.error('Renew token threw exception!')
    log.error(e)
    return null
  }
}

const doTokenRefresh = cacheParallel(internalDoTokenRefresh) // as ThunkFunction<string | null>

export function getTokenAndRefreshIfNeeded (): ThunkFunction<string> {
  log.debug('getTokenAndRefreshIfNeeded')
  return getTokenAndRefreshIfNeededThunk
}

async function internalGetTokenAndRefreshIfNeededThunk (dispatch: DispatchType, getState: GetStateFnType): Promise<string> {
  log.debug('getTokenAndRefreshIfNeededThunk')
  const { auth } = getState()
  if (auth.accessToken !== null && auth.tokenExpirationTime > 0) {
    const existingTokenExpirationTime = Number(auth.tokenExpirationTime)
    const now = Date.now()
    if (now < existingTokenExpirationTime) {
      // log.debug(`Using existing token which expires in ${(existingTokenExpirationTime - now)/1000} seconds`)
      return auth.accessToken
    }
  }

  if (auth.refreshTokenExpiresAt < Date.now()) {
    log.warn('WARN: Attempted to refresh with no refresh token in state', auth)
    dispatch(temporarilyUnauthenticated(window.location.href))
    await dispatch(initializeAuthWithPreviousProvider())
    throw new Error('Attempted to refresh renew token with no refresh token in state')
  }

  const accessToken = await doTokenRefresh(dispatch, getState)
  if (accessToken != null) {
    log.debug('Successfully renewed token!')
    return accessToken
  } else {
    log.debug('Attempting reauth with previous provider', window.location.href)
    dispatch(temporarilyUnauthenticated(window.location.href))
    await dispatch(initializeAuthWithPreviousProvider())
    // await dispatch(initializeAuthWithPreviousProvider())
    throw new Error('Renew token failed, attempting reauthentication with previous provider')
  }
}

const getTokenAndRefreshIfNeededThunk = cacheParallel(internalGetTokenAndRefreshIfNeededThunk)

export function initializeAuth (): ThunkFunction<void> {
  log.debug('initializeAuth')
  return async function (dispatch: DispatchType, getState: GetStateFnType): Promise<void> {
    log.debug('initializeAuth')
    const { auth } = getState()
    if (auth.authState === AuthState.Loading) {
      log.debug('Auth loading, bailing from initialize')
      return
    }
    try {
      if (initialRefreshTokenExpiresAt < Date.now()) {
        log.info('No valid persisted refresh token, trying previous provider..')
        dispatch(temporarilyUnauthenticated(window.location.href))
        await dispatch(initializeAuthWithPreviousProvider())
        return
      }
      log.info('Found persisted refresh token, getting auth token...')

      const accessToken = await doTokenRefresh(dispatch, getState)
      if (accessToken != null) {
        return
      }
      log.info('Refresh auth token failed, refresh token probably expired, trying previous provider..')
      dispatch(temporarilyUnauthenticated(window.location.href))
      await dispatch(initializeAuthWithPreviousProvider())
    } catch (e) {
      log.error('Exception logging in by refresh token, setting unauthenticated.')
      log.error(e)
      dispatch(unauthenticated({}))
    }
  }
}

export function initializeAuthWithRefreshToken (refreshToken: string): ThunkFunction<void> {
  log.debug('initializeAuthWithRefreshToken')
  return async function (dispatch: DispatchType, getState: GetStateFnType): Promise<void> {
    log.debug('initializeAuthWithRefreshTokenThunk')
    const { auth } = getState()
    if (auth.authState === AuthState.Loading) {
      log.debug('Auth loading, bailing from initialize')
      return
    }
    try {
      const tenantId = localStorage.getItem('tenant') ?? auth.currentTenantId

      await internalDoTokenRefresh(dispatch, getState, tenantId ?? undefined, refreshToken)
    } catch (e) {
      log.error('Exception initializing with refresh token, setting unauthenticated.')
      log.error(e)
      dispatch(unauthenticated({}))
    }
  }
}

export function switchTenant (tenantId: string): ThunkFunction<void> {
  log.info('switchTenant')
  return async function (dispatch: DispatchType, getState: GetStateFnType): Promise<void> {
    log.debug('switchTenantThunk')
    try {
      const accessToken = await doTokenRefresh(dispatch, getState, tenantId)
      log.debug('switchTenantThunk', accessToken != null ? 'succeeded' : 'failed')
      if (accessToken != null) {
        localStorage.setItem('tenant', tenantId)
      } else {
        log.warn('Error switching tenant')
        localStorage.removeItem('tenant')
        dispatch(unauthenticated({}))
      }
    } catch (e) {
      log.error('Exception switching tenant')
      log.error(e)
      localStorage.removeItem('tenant')
      dispatch(unauthenticated({}))
    }
  }
}

export function initializeAuthWithPreviousProvider (): ThunkFunction<void> {
  log.debug('initializeAuthWithPreviousProvider')
  return async function (dispatch: DispatchType, getState: GetStateFnType): Promise<void> {
    log.debug('initializeAuthWithPreviousProviderThunk')
    try {
      const { auth } = getState()
      const provider = localStorage.getItem('provider')
      if (provider == null) {
        log.info('No previous provider, setting unauthenticated.')
        dispatch(unauthenticated({}))
        return
      }
      log.debug('Previous provider', provider)
      const originalUrl = auth.redirectUrl ?? window.location.href
      log.debug('originalUrl', originalUrl)
      await dispatch(startOpenIdLogin(provider, originalUrl))
    } catch (e) {
      log.error('Error logging in with previous provider, setting unauthenticated.')
      log.error(e)
      dispatch(unauthenticated({}))
    }
  }
}

export function getOpenIdLoginUrl (provider: string, affiliate?: string, returnUrl?: string): string | undefined {
  const providerConfig = oAuthProviders[provider]
  if (providerConfig == null) {
    log.warn('Unknown provider:', provider)
    return
  }

  let redirectUrl
  if (window.location.hostname === 'localhost') {
    redirectUrl = `http://localhost:${window.location.port}/auth/${provider}`
  } else {
    redirectUrl = `https://${window.location.hostname}/auth/${provider}`
  }
  const loginUrl = new URL(providerConfig.authUri())
  for (const [key, value] of Object.entries(providerConfig.params)) {
    if (typeof value === 'function') {
      loginUrl.searchParams.append(key, value())
    } else {
      loginUrl.searchParams.append(key, value)
    }
  }
  loginUrl.searchParams.append('redirect_uri', redirectUrl)
  if (returnUrl != null || affiliate != null) {
    const state: Record<string, string> = {}
    if (affiliate != null) state.affiliate = affiliate
    if (returnUrl != null) state.returnUrl = returnUrl
    loginUrl.searchParams.append('state', JSON.stringify(state))
  }
  log.info('redirecting for OAuth provider for login', provider, loginUrl.href)
  return loginUrl.href
}

export function startOpenIdLogin (provider: string, returnUrl?: string): ThunkFunction<void> {
  log.info('startOpenIdLogin', provider)

  return async function startOpenIdLoginThunk (dispatch: DispatchType, getState: GetStateFnType) {
    log.debug('startOpenIdLoginThunk')
    const { auth } = getState()
    if (auth.authenticated && auth.authState === AuthState.Authenticated) {
      log.debug('already authenticated, bail')
      return
    }
    const providerConfig = oAuthProviders[provider]
    if (providerConfig == null) {
      log.warn('Unknown provider:', provider)
      dispatch(unauthenticated({}))
      return
    }
    dispatch(loading())

    let redirectUrl
    if (window.location.hostname === 'localhost') {
      redirectUrl = `http://localhost:${window.location.port}/auth/${provider}`
    } else {
      redirectUrl = `https://${window.location.hostname}/auth/${provider}`
    }
    const loginUrl = new URL(providerConfig.authUri())
    for (const [key, value] of Object.entries(providerConfig.params)) {
      if (typeof value === 'function') {
        loginUrl.searchParams.append(key, value())
      } else {
        loginUrl.searchParams.append(key, value)
      }
    }
    loginUrl.searchParams.append('redirect_uri', redirectUrl)
    if (returnUrl != null) loginUrl.searchParams.append('state', JSON.stringify({ returnUrl }))
    log.info('redirecting for OAuth provider for login', provider, loginUrl.href)
    window.location.href = loginUrl.href
    log.warn('redirect should have been made, we shouldn\'t reach this point', provider, loginUrl.href)
  }
}

let _tokenCode: string | null = null

const _codes: Record<string, boolean> = {}

export function completeOpenIdLogin (code: string, provider: string, stateInput?: string): ThunkFunction<void> {
  log.info('completeOpenIdLogin')

  return async function completeOpenIdLoginThunk (dispatch: DispatchType, getState: GetStateFnType) {
    log.debug('completeOpenIdLoginThunk')
    if (_codes[code]) {
      log.debug('already handled code, bail')
      return
    }
    _codes[code] = true

    let state: Record<string, string> = {}
    try {
      if (stateInput != null) {
        state = JSON.parse(stateInput)
      }
    } catch (e) {
      log.error('Error parsing state', stateInput, e)
    }
    const { affiliate, returnUrl } = state

    const { auth } = getState()
    if (auth.authenticated && auth.authState === AuthState.Authenticated) {
      log.debug('already authenticated, bail')
      return
    }
    if (_tokenCode === code) {
      log.debug('completeOpenIdLoginThunk locked')
      return
    }
    _tokenCode = code
    try {
      const tenantId = localStorage.getItem('tenant') ?? auth.currentTenantId
      const response = await fetch(`${apiRootUrl}/auth/${provider}/?code=${encodeURIComponent(code)}` + (tenantId != null ? `&tenantId=${tenantId}` : '') + (affiliate != null ? `&affiliate=${affiliate}` : ''), {
        method: 'POST'
      })
      if (!response.ok) {
        if ((await response.text()).includes('Self registration disabled')) {
          log.warn('Self registration disabled!')
          log.debug(response)
          _tokenCode = null
          dispatch(selfRegistrationDisabled())
        } else {
          log.warn('Authentication failed!')
          log.debug(response)
          _tokenCode = null
          dispatch(unauthenticated({}))
        }
        return
      }
      const result = await response.json() as AuthResult
      applyAuthResult(dispatch, auth, result, returnUrl)
    } catch (e) {
      log.error('completeLogin error', e)
      log.error(e)
      dispatch(unauthenticated({}))
    }
  }
}

export function startEmailLogin (email: string, affiliate?: string): ThunkFunction<void> {
  log.info('startEmailLogin', email)

  return async function startEmailLoginThunk (dispatch: DispatchType, getState: GetStateFnType) {
    log.debug('startEmailLoginThunk')
    const { auth } = getState()
    if (auth.authenticated) {
      log.debug('already authenticated, bail')
      return
    }
    try {
      const response = await fetch(`${apiRootUrl}/auth/email/login?email=${encodeURIComponent(email)}` + (affiliate != null ? `&affiliate=${affiliate}` : ''), {
        method: 'POST'
      })
      if (!response.ok) {
        log.warn('Authentication failed!')
        log.warn(response)
        _tokenCode = null
        dispatch(unauthenticated({}))
        return
      }
      log.debug('Request for email login verification sent')
    } catch (e) {
      log.error('startEmailLogin error', e)
      dispatch(unauthenticated({}))
    }
  }
}

export function startEmailSignup (email: string, firstName: string, lastName: string, captcha: string, affiliate?: string): ThunkFunction<void> {
  log.info('startEmailSignup', email)

  return async function startEmailSignupThunk (dispatch: DispatchType, getState: GetStateFnType) {
    log.debug('startEmailSignupThunk')
    const { auth } = getState()
    if (auth.authenticated) {
      log.debug('already authenticated, bail')
      return
    }
    try {
      const response = await fetch(`${apiRootUrl}/auth/email/signup?email=${encodeURIComponent(email)}&firstName=${encodeURIComponent(firstName)}&lastName=${encodeURIComponent(lastName)}&captcha=${encodeURIComponent(captcha)}` + (affiliate != null ? `&affiliate=${affiliate}` : ''), {
        method: 'POST'
      })
      if (!response.ok) {
        if ((await response.text()).includes('Self registration disabled')) {
          log.warn('Self registration disabled!')
          log.warn(response)
          _tokenCode = null
          dispatch(selfRegistrationDisabled())
        } else {
          log.warn('Authentication failed!')
          log.warn(response)
          _tokenCode = null
          dispatch(unauthenticated({}))
        }
        return
      }
      log.info('Request for email signup verification sent')
    } catch (e) {
      log.error('startEmailSignup error', e)
      dispatch(unauthenticated({}))
    }
  }
}

export function startEmailVerify (email: string, code: string): ThunkFunction<void> {
  log.info('startEmailVerify', email, code)

  return async function startEmailVerifyThunk (dispatch: DispatchType, getState: GetStateFnType) {
    log.debug('startEmailVerifyThunk')
    const { auth } = getState()
    if (auth.authenticated) {
      log.debug('already authenticated, bail')
      return
    }
    try {
      const response = await fetch(`${apiRootUrl}/auth/email?email=${encodeURIComponent(email)}&code=${encodeURIComponent(code)}`, {
        method: 'POST'
      })
      if (!response.ok) {
        log.warn('Email code verification failed!')
        log.warn(response)
        _tokenCode = null
        dispatch(unauthenticated({}))
        return
      }
      const result = await response.json() as AuthResult
      applyAuthResult(dispatch, auth, result)
    } catch (e) {
      log.error('startEmailVerify error', e)
      dispatch(unauthenticated({}))
    }
  }
}

export function logout (): ThunkFunction<void> {
  log.info('logout')
  return async function logoutThunk (dispatch: DispatchType) {
    log.debug('logoutThunk')
    try {
      log.debug('Removing persisted refreshToken')
      localStorage.clear()
    } finally {
      log.debug('Dispatching unauthenticated...')
      dispatch(unauthenticated({}))
      log.debug('Dispatched unauthenticated')
    }
  }
}

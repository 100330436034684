import { type PropsWithChildren, createContext, useEffect, useState, type ReactElement, useContext } from 'react'
import { clearRecording, getLogs, haveLogs, isRecording, rootLog, startRecording, stopRecording } from '../logging'
import { createBackoff, createLock, intervalWithImmediate, omit } from '../util'
import { type Headshot, type Recording, type Syncable, UploadStatus, db, dbLock } from '../db'
import { type RootState, useAppDispatch } from '../store'
import { AuthState, getTokenAndRefreshIfNeeded } from '../reducers/authReducer'
import { apiRootUrl } from '../reducers/apiSlice'
import { type ITryout } from '../../../api/api'
import { TenantSeasonContext } from './TenantSeasonProvider'
import { useGetAthletesQuery } from '../reducers/apiSlice-athletes'
import { useSelector } from 'react-redux'
import { Button, Dialog } from '@andyneville/tailwind-react'
import { ArrowTopRightOnSquareIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline'

const log = rootLog.child({ module: 'ClientSyncProvider' })

const recordingSyncInterval = 10000 // 10 seconds

const maxTimeForRecordingToProcess = 90000 // 90 seconds (should only be about 15 seconds)
// const maxTimeForHeadshotToProcess = 90000 // 90 seconds (should only be about 15 seconds)
// const timeForHighFailureTimeout = 2 * 60 * 60 * 1000 // 2 hours

// 1 = 1000
// 2 = 2000
// 3 = 4000
// 4 = 8000
// 5 = 16000
// 6 = 32000
// 7 = 64000 ~ 1 minute
// 8 = 128000 ~ 2 minutes
// 9 = 256000 ~ 4 minutes
// 10 = 512000 ~ 8 minutes
// 11 = 720000 ~ 10 minutes

function backoffTime (attempts: number): number {
  return Math.min((2 ** attempts) * 1000, 720000) // max 10 minutes
}

let haveSeenRecordingIssue = false

let haveWarnedAboutRecordingIssue = localStorage.getItem('haveWarnedAboutRecordingIssue') === 'true'

export interface ClientSyncContextType {
  videoAdded: (clientId: number) => void
  headshotAdded: (clientId: number) => void
  canPersist: string
  lastUpdatedVideo: number
  lastUpdatedHeadshot: number
  isCollectingLogs: boolean
  setIsCollectingLogs: (value: boolean) => Promise<void>
  getLogs: () => Promise<Blob>
  clearLogs: () => Promise<void>
  haveLogs: boolean
}

export const ClientSyncContext = createContext<ClientSyncContextType>({
  videoAdded: () => { },
  headshotAdded: () => { },
  canPersist: 'uninitialized',
  lastUpdatedVideo: 0,
  lastUpdatedHeadshot: 0,
  isCollectingLogs: false,
  setIsCollectingLogs: async () => { },
  getLogs: async () => new Blob(),
  clearLogs: async () => {},
  haveLogs: false
})

const updateRecordingWithBackoff = createBackoff(async (localId: number, recording: Partial<Recording>): Promise<void> => {
  log.debug('attempt recording update', localId)
  await db.recordings.update(localId, recording)
  log.debug('update complete', localId)
})

const updateHeadshotWithBackoff = createBackoff(async (localId: number, headshot: Partial<Headshot>): Promise<void> => {
  log.debug('attempt headshot update', localId)
  await db.headshots.update(localId, headshot)
  log.debug('update complete', localId)
})

type UploadFunction<T extends Syncable> = (accessToken: string, item: T) => Promise<void>
type VerifyFunction<T extends Syncable> = (accessToken: string, item: T) => Promise<boolean>

// return true if an item is updated (i.e. synced and deleted from local collection)
async function syncItem<T extends Syncable> (accessToken: string, item: T, upload: UploadFunction<T>, verify: VerifyFunction<T>, collectionName: string): Promise<boolean> {
  const now = Date.now()
  if (item.uploadStatus === UploadStatus.NotStarted) {
    if ((item.uploadAttempts ?? 1) > 1 && ((item.uploadStartedAt ?? 0) + backoffTime(item.uploadAttempts ?? 1)) > now) {
      const seconds = backoffTime(item.uploadAttempts ?? 1) / 1000
      log.debug(`${collectionName} failed ${item.uploadAttempts} times, waiting ${seconds} seconds (${seconds / 60} minutes) to retry verification`, item.localId, item.uploadStartedAt, (item.uploadStartedAt ?? 0) + backoffTime(item.uploadAttempts ?? 1) - now)
      return false
    }

    log.debug(`Start uploading ${collectionName}`, item.localId)
    await upload(accessToken, item)
  } else if (item.uploadStatus === UploadStatus.Uploaded) {
    if (item.uploadCompletedAt == null) {
      log.debug(`Restart uploading ${collectionName}`, item.localId, 'uploadCompletedAt is null')
      await upload(accessToken, item)
      return false
    }
    if ((item.uploadCompletedAt + maxTimeForRecordingToProcess) < now) {
      if ((item.uploadAttempts ?? 1) > 1 && ((item.uploadCompletedAt ?? 0) + backoffTime(item.uploadAttempts ?? 1)) > now) {
        const seconds = backoffTime(item.uploadAttempts ?? 1) / 1000
        log.debug(`${collectionName} failed ${item.uploadAttempts} times, waiting ${seconds} seconds (${seconds / 60} minutes) to retry verification`, item.localId, item.uploadStartedAt, (item.uploadStartedAt ?? 0) + backoffTime(item.uploadAttempts ?? 1) - now)
        return false
      }
      log.debug(`Restart uploading ${collectionName}`, item.localId, 'waited too long', item.uploadCompletedAt, now)
      await upload(accessToken, item)
      return false
    }
    log.debug(`Start verifying ${collectionName}`, item.localId)
    return await verify(accessToken, item)
  } else if (item.uploadStatus === UploadStatus.Verified) {
    log.debug(`Already uploaded: ${collectionName}`, item.localId)
  } else {
    log.debug(`Item in error state: ${collectionName}`, item.localId)
  }
  return false
}

async function uploadRecording (accessToken: string, recording: Recording): Promise<void> {
  try {
    let id: string
    let uploadUrl: string
    const uploadStartedAt = Date.now()
    if (recording.id == null) {
      log.debug('creating tryout recording upload')
      const response = await fetch(`${apiRootUrl}/seasons/${recording.seasonId}/athletes/${recording.athleteId}/tryouts/${recording.tryoutId}/tryoutVideo`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ mimeType: recording.videoMimeType })
      })
      if (!response.ok) {
        if (response.status === 404 || response.status === 400) {
          log.debug(`${response.status} response, moving to error state`, recording.localId, await response.text())
          await updateRecordingWithBackoff(recording.localId, { uploadAttempts: recording.uploadAttempts + 1, uploadStatus: UploadStatus.Error, uploadStartedAt })
          return
        }
        log.debug('failed to create tryout recording', response, await response.text())
        haveSeenRecordingIssue = true
        await updateRecordingWithBackoff(recording.localId, { uploadAttempts: recording.uploadAttempts + 1, uploadStatus: UploadStatus.NotStarted, uploadStartedAt })
        return
      }
      const responseJson = await response.json()
      id = responseJson.id
      uploadUrl = responseJson.uploadUrl
      // recording.id = id
      // log.debug('creating tryout recording upload')
      // await updateRecordingWithBackoff(recording.localId, { id, uploadAttempts: recording.uploadAttempts + 1, uploadStartedAt })
    } else {
      log.debug('retrying tryout recording upload')
      const response = await fetch(`${apiRootUrl}/seasons/${recording.seasonId}/athletes/${recording.athleteId}/tryouts/${recording.tryoutId}/tryoutVideoRestart/${recording.id}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ mimeType: recording.videoMimeType })
      })
      if (!response.ok) {
        if (response.status === 404 || response.status === 400) {
          log.debug(`${response.status} response, moving to error state`, recording.localId, await response.text())
          await updateRecordingWithBackoff(recording.localId, { uploadAttempts: recording.uploadAttempts + 1, uploadStatus: UploadStatus.Error, uploadStartedAt })
          return
        }
        log.debug('failed to restart tryout recording', response, await response.text())
        haveSeenRecordingIssue = true
        await updateRecordingWithBackoff(recording.localId, { uploadAttempts: recording.uploadAttempts + 1, uploadStatus: UploadStatus.NotStarted, uploadStartedAt })
        return
      }
      log.debug('retrying tryout recording upload - status ok')
      const responseJson = await response.json()
      id = responseJson.id
      uploadUrl = responseJson.uploadUrl
      // recording.id = id
      // log.debug('retrying tryout recording upload')
      // await updateRecordingWithBackoff(recording.localId, { id, uploadAttempts: recording.uploadAttempts + 1, uploadStartedAt })
    }
    log.debug('fetching local recording video')
    const video = await db.recordingVideos.get(recording.localId ?? 0)
    if (video == null) {
      log.debug('no video found')
      await updateRecordingWithBackoff(recording.localId, { uploadAttempts: recording.uploadAttempts + 1, uploadStatus: UploadStatus.Error, uploadStartedAt })
      return
    }
    await updateRecordingWithBackoff(recording.localId, { id, uploadAttempts: recording.uploadAttempts + 1, uploadStartedAt })
    log.debug(`uploading tryout recording to ${uploadUrl}`)
    const uploadResult = await fetch(uploadUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': recording.videoMimeType
      },
      body: video.video
    })
    if (uploadResult.status < 200 && uploadResult.status >= 300) {
      log.debug('addTryoutVideo - upload failed', uploadResult, await uploadResult.text())
      await updateRecordingWithBackoff(recording.localId, { id, uploadAttempts: recording.uploadAttempts + 1, uploadStartedAt, uploadStatus: UploadStatus.NotStarted })
      return
    }
    log.debug('upload completed', id, recording.localId)
    await updateRecordingWithBackoff(recording.localId, { uploadCompletedAt: Date.now(), uploadStatus: UploadStatus.Uploaded })
  } catch (err) {
    log.error('Exception uploading tryout recording', err)
    haveSeenRecordingIssue = true
    await updateRecordingWithBackoff(recording.localId, { uploadAttempts: recording.uploadAttempts + 1, uploadStartedAt: Date.now(), uploadStatus: UploadStatus.NotStarted })
  }
}

async function verifyRecording (accessToken: string, recording: Recording): Promise<boolean> {
  log.debug('verifying recording', recording.localId, recording.uploadCompletedAt, recording.seasonId, recording.athleteId, recording.tryoutId)

  const result = await fetch(`${apiRootUrl}/seasons/${recording.seasonId}/athletes/${recording.athleteId}/tryouts/${recording.tryoutId}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })
  if (result.status !== 200) {
    log.debug('fetch recording updated status - got no result', recording.localId, result.status, result.statusText)
    return false
  }
  const data = await result.json() as ITryout
  if (data == null) {
    log.debug('fetch recording updated status - tryout not foud')
    return false
  }
  const foundRecording = (data.recordings ?? []).find(r => r.id === recording.id)
  if (foundRecording?.thumbnailUrl == null || foundRecording.videoUrl == null) {
    log.debug('fetch recording updated status - thumbnail or recording not found', foundRecording)
    return false
  }
  log.debug('fetch recording updated status - remote item found, removing local item', recording.localId)
  if (recording.localId != null) {
    await db.recordings.delete(recording.localId)
    await db.recordingVideos.delete(recording.localId)
    await db.recordingThumbnails.delete(recording.localId)
  }
  log.debug('recording confirmed, deleted local copy', recording.localId, recording.seasonId, recording.athleteId, recording.tryoutId)
  return true
}

async function uploadHeadshot (accessToken: string, headshot: Headshot): Promise<void> {
  try {
    const uploadStartedAt = Date.now()
    log.debug('creating headshot upload')
    const response = await fetch(`${apiRootUrl}/seasons/${headshot.seasonId}/athletes/${headshot.athleteId}/headshot`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ mimeType: headshot.headshotMimeType })
    })
    if (!response.ok) {
      if (response.status === 404 || response.status === 400) {
        log.debug(`${response.status} response, moving to error state`, headshot.localId, await response.text())
        await updateRecordingWithBackoff(headshot.localId, { uploadAttempts: headshot.uploadAttempts + 1, uploadStatus: UploadStatus.Error, uploadStartedAt })
        return
      }
      log.debug('failed to create headshot', response, await response.text())
      await updateHeadshotWithBackoff(headshot.localId, { uploadAttempts: headshot.uploadAttempts + 1, uploadStatus: UploadStatus.NotStarted, uploadStartedAt })

      log.debug('failed to create headshot', response, await response.text())
      throw new Error('Failed to create headshot')
    }
    const { id, uploadUrl, downloadUrl } = await response.json() as { id: string, uploadUrl: string, downloadUrl: string }
    await updateHeadshotWithBackoff(headshot.localId, { uploadAttempts: headshot.uploadAttempts + 1, uploadStatus: UploadStatus.NotStarted, uploadStartedAt, headshotUrl: downloadUrl })

    log.debug('fetching headshot image from local database')
    const image = await db.headshotImages.get(headshot.localId ?? 0)
    if (image == null) {
      log.debug('no image found')
      await updateHeadshotWithBackoff(headshot.localId, { uploadStatus: UploadStatus.Error, uploadStartedAt })
      return
    }
    log.debug(`uploading headshot to ${uploadUrl}`)
    const uploadResult = await fetch(uploadUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': headshot.headshotMimeType
      },
      body: image.headshot
    })
    if (uploadResult.status < 200 && uploadResult.status >= 300) {
      log.debug('addheadshot - upload failed', uploadResult, await uploadResult.text())
      throw new Error('Failed to upload headshot')
    }
    await updateHeadshotWithBackoff(headshot.localId, { uploadCompletedAt: Date.now(), uploadStatus: UploadStatus.Uploaded })
    log.debug('uploaded headshot', id, headshot.localId)
  } catch (err) {
    log.error('Exception uploading headshot', err)
    await updateHeadshotWithBackoff(headshot.localId, { uploadAttempts: headshot.uploadAttempts + 1, uploadStartedAt: Date.now(), uploadStatus: UploadStatus.NotStarted })
  }
}

async function verifyHeadshot (accessToken: string, headshot: Headshot): Promise<boolean> {
  log.debug('verifying headshot', headshot.localId, headshot.uploadCompletedAt, headshot.seasonId, headshot.athleteId)

  const result = await fetch(`${apiRootUrl}/seasons/${headshot.seasonId}/athletes/${headshot.athleteId}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })
  if (result.status !== 200) {
    log.debug('fetch headshot updated status - got no result', headshot.localId, result.status, result.statusText)
    return false
  }

  const athlete = await result.json()
  if (athlete?.headshotUrl == null) {
    log.debug('fetch headshot updated status - headshot not found')
    return false
  }
  if (headshot.localId != null) {
    await db.headshots.delete(headshot.localId)
    await db.headshotImages.delete(headshot.localId)
  }
  log.debug('headshot confirmed, deleted local copy', headshot.localId, headshot.seasonId, headshot.athleteId)
  return true
}

const recordingLock = createLock(dbLock('recordingSync'))
async function syncRecordings (accessToken: string): Promise<boolean> {
  log.debug('syncRecordingsx')
  let updated = false
  await recordingLock(async (): Promise<void> => {
    log.debug('doing recording sync cycle')
    const recordings = await db.recordings.toArray()
    if (recordings.length === 0) {
      log.debug('no recordings to syncx')
      return
    }

    log.debug('recordings to sync', recordings?.map(r => omit(r, 'thumbnail', 'video')))

    for (const recording of recordings) {
      if (await syncItem(accessToken, recording, uploadRecording, verifyRecording, 'recording')) {
        updated = true
      }
    }
  })
  log.debug('syncRecordings', updated ? 'updated' : 'not updated')
  return updated
}

const headshotLock = createLock(dbLock('headshotSync'))
async function syncHeadshots (accessToken: string): Promise<boolean> {
  log.debug('syncHeadshots')
  let updated = false
  await headshotLock(async (): Promise<void> => {
    log.debug('doing headshot sync cycle')
    const headshots = await db.headshots.toArray()
    if (headshots.length === 0) {
      log.debug('no headshots to sync')
      return
    }
    log.debug('headshots to sync', headshots)
    for (const headshot of headshots) {
      if (await syncItem(accessToken, headshot, uploadHeadshot, verifyHeadshot, 'headshot')) {
        updated = true
      }
    }
  })
  log.debug('syncHeadshots', updated ? 'updated' : 'not updated')
  return updated
}

export default function ClientSyncProvider (props: PropsWithChildren): ReactElement {
  const { season } = useContext(TenantSeasonContext)
  const { authState } = useSelector((state: RootState) => state.auth)
  const [showVideoWarning, setShowVideoWarning] = useState(false)

  const doGetLogs = async (): Promise<Blob> => {
    const blob = new Blob([await getLogs()], { type: 'text/plain' })
    return blob
  }

  const {
    refetch: refetchAthletes
  } = useGetAthletesQuery({ seasonId: season?.id ?? '' }, { skip: authState !== AuthState.Authenticated || season == null })
  const { children } = props
  const [canPersist, setCanPersist] = useState('uninitialized')
  const [lastUpdatedVideo, setLastUpdatedVideo] = useState(0)
  const [lastUpdatedHeadshot, setLastUpdatedHeadshot] = useState(0)
  const [currentlyRecording, setCurrentlyRecording] = useState(isRecording())
  const [doHaveLogs, setDoHaveLogs] = useState(false)
  const videoAdded = (clientId: number): void => {
    log.debug('videoAdded', clientId)
  }
  const headshotAdded = (clientId: number): void => {
    log.debug('headshotAdded', clientId)
  }
  const dispatch = useAppDispatch()

  useEffect(() => {
    void (async () => {
      setDoHaveLogs(await haveLogs())
    })()
  }, [])

  useEffect(() => {
    log.debug('ClientSyncProvider useEffect')
    const { stop } = intervalWithImmediate(async (): Promise<void> => {
      if (authState !== AuthState.Authenticated) {
        return
      }
      if (haveSeenRecordingIssue && !haveWarnedAboutRecordingIssue) {
        haveWarnedAboutRecordingIssue = true
        localStorage.setItem('haveWarnedAboutRecordingIssue', 'true')
        setShowVideoWarning(true)
      }

      const recordings = await db.recordings.toArray()
      const headshots = await db.headshots.toArray()
      if (recordings.length === 0 && headshots.length === 0) {
        log.debug('no recordings or headshots to sync')
        return
      }
      const accessToken = await dispatch(getTokenAndRefreshIfNeeded())
      try {
        log.debug('ue syncRecordings')
        let updated = await syncRecordings(accessToken)
        if (updated) {
          log.debug('updated, setting lastUpdatedVideo')
          setLastUpdatedVideo(Date.now())
        }
        log.debug('ue syncHeadshots')
        updated = await syncHeadshots(accessToken)
        if (updated) {
          log.debug('updated, setting lastUpdatedVideo')
          setLastUpdatedHeadshot(Date.now())
          void refetchAthletes()
        }
        log.debug('ue done')
      } catch (err) {
        log.error('Exception in syncRecordings', err)
      }
    }, recordingSyncInterval, true)
    return () => {
      stop()
    }
  }, [dispatch, refetchAthletes, authState])

  log.debug('canPersist', canPersist)

  useEffect(() => {
    async function checkStorage (): Promise<void> {
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (!navigator.storage?.persisted) {
        setCanPersist('never')
        return
      }
      let persisted = await navigator.storage.persisted()
      if (persisted) {
        setCanPersist('persisted1')
        return
      }
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (navigator.permissions?.query == null) {
        setCanPersist('prompt1')
        return // It MAY be successful to prompt. Don't know.
      }
      try {
        const permission = await navigator.permissions.query({
          name: 'persistent-storage'
        })
        if (permission.state === 'granted') {
          persisted = await navigator.storage.persist()
          if (persisted) {
            setCanPersist('persisted2')
            return
          } else {
            throw new Error('Failed to persist')
          }
        }
        if (permission.state === 'prompt') {
          setCanPersist('prompt2')
          return
        }
      } catch {
        // Safari seems not to support navigator.permissions.query
        setCanPersist('prompt3')
        return // It MAY be successful to prompt. Don't know.
      }
      setCanPersist('never')
    }
    void checkStorage()
  }, [])

  return (
    <ClientSyncContext.Provider value={{
      videoAdded,
      headshotAdded,
      canPersist,
      lastUpdatedVideo,
      lastUpdatedHeadshot,
      isCollectingLogs: currentlyRecording,
      setIsCollectingLogs: async (value) => {
        if (value) {
          await startRecording()
          setDoHaveLogs(await haveLogs())
        } else {
          await stopRecording()
          setDoHaveLogs(await haveLogs())
        }
        setCurrentlyRecording(isRecording())
      },
      getLogs: async () => {
        return await doGetLogs()
      },
      clearLogs: async () => {
        await clearRecording()
        setCurrentlyRecording(isRecording())
        setDoHaveLogs(false)
      },
      haveLogs: doHaveLogs
    }}>
      <Dialog
        open={showVideoWarning}
        title="Video Recording Warning"
        Icon={ExclamationCircleIcon}
        iconClassName='rounded-full text-red-500 bg-red-200 dark:text-red-400 dark:bg-red-500/30 w-10 h-10 p-1'
        onClose={() => { setShowVideoWarning(false) }}
        buttons={(<>
          <Button className='sm:ml-3' label="OK" primary onClick={() => { setShowVideoWarning(false) }} />
        </>)}
      >
        <p>
          We have detected an issue with this device that may cause videos to not be stored properly.
          We are investigating a solution, but a small number of customers with similar problems have lost videos
          and in the meantime we recommend you pay close attention to your video uploads.
        </p>
        <p className="pt-4">
          Please see our <a href="https://cheersyncapp.com/faq" target='_blank'>FAQ page <ArrowTopRightOnSquareIcon className='-mt-1 inline w-4' /></a> for more information and instructions.
        </p>
        <p className="pt-4">
          Please reach out to our support at <a href="mailto:support@cheersync.app" target='_blank'>support@cheersync.app</a> if you have any trouble!
        </p>
      </Dialog>
      {children}
    </ClientSyncContext.Provider>
  )
}

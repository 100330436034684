import { Button, GlobalDialogContext, LoadingComponent, Mask, PageHeader } from '@andyneville/tailwind-react'
import { useLiveQuery } from 'dexie-react-hooks'
import { useContext, useEffect, useState, type ReactElement } from 'react'
import { type Recording, UploadStatus, db } from '../db'
import { rootLog } from '../logging'
import LocalVideoPreview from '../components/LocalVideoPreview'
import LocalPlayVideoDialog from '../components/LocalPlayVideoDialog'
import { ArrowPathIcon } from '@heroicons/react/24/outline'

const emptyBlob = new Blob()

const log = rootLog.child({ module: 'ClientSyncProvider' })

const mimeTypeExtensions: Record<string, string> = {
  'video/mp4': 'mp4',
  'video/quicktime': 'mov',
  'video/x-msvideo': 'avi',
  'video/x-flv': 'flv',
  'video/x-matroska': 'mkv',
  'video/x-ms-wmv': 'wmv',
  'video/webm': 'webm',
  'video/3gpp': '3gp',
  'video/3gpp2': '3g2',
  'video/ogg': 'ogv',
  'video/x-m4v': 'm4v',
  'video/x-ms-asf': 'asf',
  'video/x-mpeg': 'mpg',
  'video/x-ms-wm': 'wm',
  'video/x-ms-wmx': 'wmx',
  'video/x-ms-wvx': 'wvx',
  'video/x-ms-wmz': 'wmz',
  'image/jpeg': 'jpg',
  'image/png': 'png',
  'image/gif': 'gif',
  'image/bmp': 'bmp',
  'image/svg+xml': 'svg',
  'image/tiff': 'tiff',
  'image/webp': 'webp'
}

export default function LocalVideos (): ReactElement {
  const recordings = useLiveQuery<Recording[]>(
    async () => await db.recordings
      .toArray()
  )
  const globalDialog = useContext(GlobalDialogContext)
  const [showLocalVideoDialog, setShowLocalVideoDialog] = useState(false)
  const [thumbnailUrl, setThumbnailUrl] = useState('')
  const [videoBlob, setVideoBlob] = useState<Blob>(emptyBlob)
  const [isDownloading, setIsDownloading] = useState(false)

  const showLocalVideo = (video?: Blob, thumbnail?: string): void => {
    setVideoBlob(video ?? emptyBlob)
    setThumbnailUrl(thumbnail ?? '')
    setShowLocalVideoDialog(true)
  }

  const doDownload = (recording: Recording): void => {
    setIsDownloading(true)
    async function download (): Promise<void> {
      const element = document.createElement('a')
      const video = await db.recordingVideos.get(recording.localId ?? 0)
      if (video == null) {
        log.debug('no video found')
        return
      }
      const url = URL.createObjectURL(video.video)
      element.href = url
      const extension = mimeTypeExtensions[recording.videoMimeType] ?? 'mp4'
      element.download = `CheerSync Tryout Recording.${extension}`
      // simulate link click
      document.body.appendChild(element)
      // Required for this to work in FireFox
      element.click()
      URL.revokeObjectURL(url)
      setIsDownloading(false)
    }
    void download()
  }

  const doRestartUploads = (): void => {
    async function restartUploads (): Promise<void> {
      const recordings = await db.recordings.toArray()
      for (const recording of recordings) {
        recording.uploadAttempts = 0
        recording.uploadStatus = UploadStatus.NotStarted
        recording.uploadStartedAt = 0
        await db.recordings.put(recording)
      }
    }
    void restartUploads()
  }

  const doDelete = (recording: Recording): void => {
    globalDialog.showWarning('Confirm Delete?', 'Are you sure you want to delete this local video? If you have not downloaded it, it will be permanently lost.', 'Delete', () => {
      void db.recordings.delete(recording.localId)
    })
  }

  const [recordingThumbnails, setRecordingThumbnails] = useState<Record<number, string>>([])
  const [recordingVideos, setRecordingVideos] = useState<Record<number, Blob>>([])
  useEffect(() => {
    // eslint-disable-next-line no-inner-declarations
    async function fetchRecordingsData (): Promise<void> {
      if (recordings != null) {
        try {
          const thumbnails: Record<number, string> = {}
          const videos: Record<number, Blob> = {}
          for (const recording of recordings) {
            const video = await db.recordingVideos.get(recording.localId ?? 0)
            if (video != null) {
              videos[recording.localId ?? 0] = video.video
            }
            const thumbnail = await db.recordingThumbnails.get(recording.localId ?? 0)
            if (thumbnail != null) {
              thumbnails[recording.localId ?? 0] = thumbnail.thumbnail
            }
          }
          setRecordingThumbnails(thumbnails)
          setRecordingVideos(videos)
        } catch (error) {
          log.error('Exception loading recordings', error)
        }
      }
    }
    void fetchRecordingsData()
  }, [recordings])

  return (
    <>
      <LocalPlayVideoDialog autoPlay title='Uprocessed Recording' open={showLocalVideoDialog} onClose={() => { setShowLocalVideoDialog(false) }} thumbnail={thumbnailUrl} video={videoBlob ?? emptyBlob} onEnded={() => { setShowLocalVideoDialog(false) }} />
      <Mask show={isDownloading}>
        <PageHeader
          title='Local Videos'
          subtitle='A list of all videos on this device which have not yet been uploaded to CheerSync'
        >
          {recordings != null && recordings.length > 0 &&
            <Button className='ml-4' Icon={ArrowPathIcon} primary label='Restart' onClick={() => { doRestartUploads() }} />
          }
        </PageHeader>
        {recordings?.length === 0
          ? <LoadingComponent isEmpty={true} emptyMessage='All videos have been uploaded, no unprocessed videos remain on this device.' />
          : (
            <>
              <div>The following videos have not yet been uploaded</div>
              <div className='mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4'>
                {recordings?.map((recording) => (
                  <div key={recording.localId} className='bg-gray-200 dark:bg-dark-700 shadow overflow-hidden sm:rounded-lg'>
                    <div className='flex flex-row items-center px-4 py-5 sm:px-6'>
                      <LocalVideoPreview
                        key={recording.localId}
                        className='w-16 h-32 mr-4'
                        thumbnail={recordingThumbnails[recording.localId ?? 0] ?? ''}
                        uploadAttempts={recording.uploadAttempts}
                        uploadStatus={recording.uploadStatus}
                        onClick={() => { showLocalVideo(recordingVideos[recording.localId ?? 0], recordingThumbnails[recording.localId ?? 0]) }}
                      />
                      <div className='flex flex-col items-center gap-y-2'>
                        <Button label='Download' primary disabled={isDownloading} onClick={() => { doDownload(recording) }} />
                        <Button label='Delete' primary disabled={isDownloading} onClick={() => { doDelete(recording) }} />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
            )
        }
      </Mask>
    </>
  )
}

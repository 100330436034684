import { type ReactElement } from 'react'
import { classes } from '@andyneville/tailwind-react'
import { PlayCircleIcon } from '@heroicons/react/24/solid'
import { CloudArrowUpIcon } from '@heroicons/react/24/outline'
import { UploadStatus } from '../db'

interface LocalVideoPreviewProps {
  onClick?: () => void
  className?: string
  thumbnail: string
  uploadAttempts?: number
  uploadStatus?: UploadStatus
}

export default function LocalVideoPreview (props: LocalVideoPreviewProps): ReactElement {
  const { onClick, thumbnail, className, uploadAttempts, uploadStatus } = props
  const Inner = (
    <>
      <PlayCircleIcon className='w-12 h-12 text-gray-400 opacity-80 group-hover:text-brand-600 group-hover:dark:text-brand-500 group-hover:opacity-100' />
      <CloudArrowUpIcon className='absolute text-gray-50 dark:text-dark-50 bottom-0 left-0 w-6 h-6' />
      {uploadAttempts != null &&
        <div className={classes('absolute text-white/90 text-xs bottom-1 right-1 w-4 h-4 rounded-full flex items-center justify-center', uploadStatus === UploadStatus.Verified ? 'bg-green-500/70' : uploadStatus === UploadStatus.Uploaded ? 'bg-blue-500/70' : uploadStatus === UploadStatus.Error ? 'bg-red-500/70' : 'bg-brand-500/70')}>{uploadAttempts}</div>
      }
    </>
  )
  if (thumbnail != null && thumbnail.length > 0) {
    return (
      <div className={classes('relative cursor-pointer bg-center bg-cover rounded-lg flex items-center justify-center group', className)} style={{ backgroundImage: `url("${thumbnail}")` }} onClick={onClick}>
        {Inner}
      </div>
    )
  } else {
    return (
      <div className={classes('relative cursor-pointer border border-gray-400 rounded-lg flex items-center justify-center group', className)} onClick={onClick}>
        {Inner}
      </div>
    )
  }
}

import { type ReactElement, useEffect, useMemo, useState, useContext } from 'react'

import { Button, GlobalDialogContext, LoadingComponent, PageHeader, SelectBox, classes } from '@andyneville/tailwind-react'
import AthleteCard from '../components/AthleteCard'
import SeasonDialog from '../components/SeasonDialog'
import { useGetAthletesQuery } from '../reducers/apiSlice-athletes'
import { ArrowDownTrayIcon, CalendarIcon, NoSymbolIcon, PlusIcon } from '@heroicons/react/24/outline'
import ImportDialog from '../components/ImportDialog'
import AddAthleteDialog from '../components/AddAthleteDialog'
import EmptyListBox from '../components/EmptyListBox'
import { TenantSeasonContext } from '../components/TenantSeasonProvider'
import { rootLog } from '../logging'
import { useSelector } from 'react-redux'
import { type RootState } from '../store'
import { rtkErrorToString } from '../util'
import { ageGridDivisions, ageGroupsByGrid, ageGroupsFromBirthYear } from '../../../api/ageGrids'
import { type SelectOption } from '@andyneville/tailwind-react/SelectBox'
import { type AgeGroup } from '../../../api/api'

const log = rootLog.child({ module: 'RosterView' })

const levelGroupCss = (enabled: boolean): string => {
  return 'text-gray-600 ' + (enabled ? 'bg-gray-50 ring-gray-600 ring-2' : 'bg-white dark:bg-dark-700 ring-gray-600/20')
}

export default function Roster (): ReactElement {
  const { tenant, season, selectedAgeGridOption, setSelectedAgeGridOption } = useContext(TenantSeasonContext)
  const { currentPlanInfo } = useSelector((state: RootState) => state.auth)

  log.debug('Roster: tenant', tenant, 'season', season)
  const {
    data: athletes = [],
    isLoading,
    isFetching,
    isError,
    refetch: refetchAthletes,
    error: athletesError
  } = useGetAthletesQuery({ seasonId: season?.id ?? '' }, { skip: season == null })
  const [showAgeGroups, setShowAgeGroups] = useState<Record<string, boolean | undefined>>({} as unknown as Record<string, boolean | undefined>)
  const [showSeasonDialog, setShowSeasonDialog] = useState(false)
  const [showImportDialog, setShowImportDialog] = useState(false)
  const [showAddAthleteDialog, setShowAddAthleteDialog] = useState(false)
  const globalDialog = useContext(GlobalDialogContext)

  useEffect(() => {
    if (athletesError != null) {
      globalDialog.popupError('Error loading athletes', 'There was an error loading the athletes: ' + rtkErrorToString(athletesError))
      log.error('Error loading athlete', athletesError)
    }
  }, [athletesError, globalDialog])

  useEffect(() => {
    if (season == null) {
      setShowSeasonDialog(true)
    }
  }, [season])

  const filteredAthletes = useMemo(() => {
    if (athletes == null || athletes.length === 0 || season == null) {
      return []
    }
    log.debug('filtering athletes', athletes.length, season)
    const sortedAthletes = athletes.slice().sort((a, b) => a.lastName.localeCompare(b.lastName))
    //    if (!showTiny && !showMini && !showYouth && !showJunior && !showSenior) {
    //      return sortedAthletes
    //    }
    const showAll = Object.values(showAgeGroups).every((v) => v)
    return sortedAthletes.map((athlete) =>
      Object.assign({}, athlete, { ageGroups: ageGroupsFromBirthYear(selectedAgeGridOption.id, athlete.birthYear, athlete.lteMay31, athlete.gteJune1) })
    ).filter((athlete) => {
      if (showAll) return true
      for (const [ageGroupId, show] of Object.entries(showAgeGroups)) {
        if (show === true && ((athlete.ageGroups?.includes(ageGroupId as AgeGroup)) ?? false)) return true
      }
      return false
    })
  }, [selectedAgeGridOption, showAgeGroups, athletes, season])

  const ageGroupOptions = useMemo(() => {
    const options = ageGroupsByGrid(selectedAgeGridOption.id)
    const showGroups = {} as unknown as Record<string, boolean | undefined>
    for (const option of options) {
      // showGroups[option.id as AgeGridId] = true
      showGroups[option.name] = true
    }
    setShowAgeGroups(showGroups)
    return options
  }, [selectedAgeGridOption])

  const onImportClose = (updated?: boolean): void => {
    log.debug('onImportClose', updated)
    setShowImportDialog(false)
    if (updated === true) {
      void refetchAthletes()
    }
  }
  const showAgeGroup = (id: string): boolean => {
    return showAgeGroups[id] === true
  }
  const toggleShowAgeGroup = (id: string): void => {
    setShowAgeGroups({ ...showAgeGroups, [id]: showAgeGroups[id] !== true })
  }
  const canAddAthletes = currentPlanInfo?.maxAthletes == null || athletes.length < currentPlanInfo.maxAthletes
  return (
    <>
      <SeasonDialog open={showSeasonDialog} onClose={() => { setShowSeasonDialog(false) }} />
      <ImportDialog open={showImportDialog} onClose={onImportClose} />
      <AddAthleteDialog open={showAddAthleteDialog} seasonId={season?.id ?? ''} onClose={() => { setShowAddAthleteDialog(false) }} />
      <PageHeader
        title={'Tryout Roster: ' + (season?.name ?? 'No Season Selected')}
        subtitle={tenant?.name}
      >
        <Button className='ml-4' Icon={CalendarIcon} primary={season == null} label='Change Season' onClick={() => { setShowSeasonDialog(true) }} />
        {season != null &&
          <>
            <Button className='ml-4' Icon={canAddAthletes ? ArrowDownTrayIcon : NoSymbolIcon} label='Import' disabled={!canAddAthletes} onClick={() => { setShowImportDialog(true) }} />
            <Button className='ml-4' Icon={canAddAthletes ? PlusIcon : NoSymbolIcon} primary label={canAddAthletes ? 'Add Athlete' : `Max Athletes on ${currentPlanInfo?.name ?? 'Current'} Plan` } disabled={!canAddAthletes} onClick={() => { setShowAddAthleteDialog(true) }} />
          </>
        }

      </PageHeader>
      {season != null &&
        <>
          <div className="flex flex-row -mb-2 space-x-4">
            <div className='flex flex-row items-center mb-2 sm:flex-grow'>
              <div className='text-sm'>Age Grid:</div>
              <SelectBox outerClassName='w-96' className='!ring-0' options={ageGridDivisions as unknown as SelectOption[]} value={selectedAgeGridOption} onChange={setSelectedAgeGridOption} />
            </div>
          </div>
          <div className="flex flex-row mb-2 space-x-4">
            <div className='flex flex-row flex-wrap items-center mb-2 space-x-2 sm:flex-grow'>
              <div className='text-sm'>Age Groups:</div>
              {ageGroupOptions.map((option) => (
                <button key={option.id} className={classes('inline-flex flex-shrink-0 items-center rounded-full px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset', levelGroupCss(showAgeGroup(option.name)))} onClick={() => { toggleShowAgeGroup(option.name) }}>{option.name}</button>
              ))}
            </div>
          </div>
        </>
      }
      <LoadingComponent isLoading={isLoading || isFetching} isError={isError} error={athletesError}>
        {season == null &&
          <EmptyListBox name='season' title='No season selected' text='Get started by clicking the "Change Season" button above and either select an existing season or create a new one.' />
        }
        {season != null && athletes.length === 0 &&
          <EmptyListBox name='athlete' text='Get started by clicking the "Add Athlete" button above, or use the "Import" button to import athletes from a Google Sheet.' />
        }
        {season != null && athletes.length > 0 &&
          <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
            {filteredAthletes.map((athlete, athleteIdx) => (
              <AthleteCard
                key={athleteIdx}
                athlete={athlete}
                showAgeGroups={showAgeGroups}
              />
            ))}
          </ul>
        }
      </LoadingComponent>
    </>
  )
}

import { type AgeGroup } from './api'
import * as data from './ageGrids.json'
// import { writeFileSync } from 'fs'

export const allAgeGridNames = data.gridNames
  .reduce((prev: Record<string, string>, curr) => {
    prev[curr.id] = curr.name
    return prev
  }, {})
// writeFileSync('ageGrids-allAgeGridNames.json', JSON.stringify(allAgeGridNames, null, 2))

export const ageGridNames = data.gridNames
  .filter((grid) => grid.active)
  .reduce((prev: Record<string, string>, curr) => {
    prev[curr.id] = curr.name
    return prev
  }, {})

// writeFileSync('ageGrids-ageGridNames.json', JSON.stringify(ageGridNames, null, 2))

// {
//   'usasf-eliteclub-2024': 'USASF Elite Tier Club Divisions (2024-25)',
//   'usasf-eliteint-2024': 'USASF Elite Tier International Divisions (2024-25)',
//   'usasf-prep-2024': 'USASF Prep Tier Divisions (2024-25)',
//   'usasf-novice-2024': 'USASF Novice Tier Divisions (2024 - 2025)',
//   'iasf-cheer-2024': 'IASF Cheer (2024-25)',
//   'open-elite-2024': 'Open All Star Elite Divisons (2024-25)',
//   'open-int-2024': 'Open All Star International Divisons (2024-25)',
//   'open-prep-2024': 'Open All Star Prep Divisions (2024-25)',
//   'open-novice-2024': 'Open All Star Novice Divisons (2024-25)'
// }

export type AgeGridId = keyof typeof ageGridNames
export interface AgeGridOption { id: string, name: string }
export const ageGridDivisions: AgeGridOption[] = Object.entries(ageGridNames).map(([id, name]) => ({ id, name }))
/*
[
  { id:'usasf-eliteclub-2024', name: 'USASF Elite Tier Club Divisions (2024-25)' }
  ...
]
*/

export function getAgeGridOption (ageGridId: AgeGridId): AgeGridOption {
  return ageGridDivisions.find((option) => option.id === ageGridId as unknown as string) ?? { id: 'usasf-eliteclub-2025', name: ageGridNames['usasf-eliteclub-2025'] }
}

export const levelNames = data.levels
  .reduce((prev: Record<string, string>, curr) => {
    prev[curr.id] = curr.name
    return prev
  }, {})
// writeFileSync('ageGrids-levelNames.json', JSON.stringify(levelNames, null, 2))
/* {
  L1: 'L1',
  L2: 'L2',
  L3: 'L3',
  L4: 'L4',
  'L4.2': 'L4.2',
  L5: 'L5',
  L6: 'L6',
  L7: 'L7'
}
 */

export const allLevels = Object.keys(levelNames) // as LevelId[]

export type LevelId = keyof typeof levelNames
export interface LevelOption { id: string, name: string }
export const levels: LevelOption[] = Object.entries(levelNames).map(([id, name]) => ({ id, name }))
/*
[
  { id:'L1', name: 'L1' }
  ...
]
*/

export const ageGroupNames = data.ageGroups
  .reduce((prev: Record<string, string>, curr) => {
    prev[curr.id] = curr.name
    return prev
  }, {})
// writeFileSync('ageGrids-ageGroupNames.json', JSON.stringify(ageGroupNames, null, 2))
// {
//   Tiny: 'Tiny',
//   Mini: 'Mini',
//   Youth: 'Youth',
//   Junior: 'Junior',
//   Senior: 'Senior',
//   'Senior Open': 'Senior Open',
//   Open: 'Open',
//   'Open L7': 'Open L7',
//   U6: 'U6',
//   U8: 'U8',
//   U12: 'U12',
//   U16: 'U16',
//   U17: 'U17',
//   U18: 'U18',
//   U19: 'U19'
// }

export const ageGroupNamesShort = data.ageGroups
  .reduce((prev: Record<string, string>, curr) => {
    prev[curr.id] = curr.short
    return prev
  }, {})
// writeFileSync('ageGrids-ageGroupNamesShort.json', JSON.stringify(ageGroupNamesShort, null, 2))
// {
//   Tiny: 'T',
//   Mini: 'M',
//   Youth: 'Y',
//   Junior: 'J',
//   Senior: 'S',
//   'Senior Open': 'SO',
//   Open: 'O',
//   'Open L7': 'OL7',
//   U6: 'U6',
//   U8: 'U8',
//   U12: 'U12',
//   U16: 'U16',
//   U17: 'U17',
//   U18: 'U18',
//   U19: 'U19'
// }

export type AgeGroupId = keyof typeof ageGroupNames
export interface AgeGroupOption { id: string, name: string }
export const ageGridLevels: Record<AgeGridId, Array<{ id: string, name: AgeGroupId, gte?: number, lte?: number, lteMay31?: boolean, gteJune1?: boolean, levels: LevelId[] }>> = {}
export const allAgeGridLevels: Record<AgeGridId, Array<{ id: string, name: AgeGroupId, gte?: number, lte?: number, lteMay31?: boolean, gteJune1?: boolean, levels: LevelId[] }>> = {}
for (const gridName in allAgeGridNames) {
  const gridEntries = data.grids.filter((grid) => grid.grid === gridName).map((grid) => {
    return {
      id: grid.id,
      name: grid.ageGroup,
      gte: grid.gteYear,
      lte: grid.lteYear,
      lteMay31: grid.lteMay31,
      gteJune1: grid.gteJune1,
      levels: allLevels.filter((level) => (grid.levels as Record<string, boolean>)[level])
    }
  })
  allAgeGridLevels[gridName] = gridEntries
  if (ageGridNames[gridName] != null) ageGridLevels[gridName] = gridEntries
}
// writeFileSync('ageGrids-ageGridLevels.json', JSON.stringify(ageGridLevels, null, 2))
// writeFileSync('ageGrids-allAgeGridLevels.json', JSON.stringify(allAgeGridLevels, null, 2))

// {
//   'usasf-eliteclub-2024': [
//     { id: 'usasf-eliteclub-2024-tiny', name: 'Tiny', gte: 2017, lte: 2019, levels: ['L1'] },
//     { id: 'usasf-eliteclub-2024-mini', name: 'Mini', gte: 2015, lte: 2018, levels: ['L1', 'L2'] },
//     { id: 'usasf-eliteclub-2024-youth', name: 'Youth', gte: 2012, lte: 2017, levels: ['L1', 'L2', 'L3', 'L4', 'L5'] },
//     { id: 'usasf-eliteclub-2024-junior', name: 'Junior', gte: 2009, lte: 2016, levels: ['L1', 'L2', 'L3', 'L4', 'L5', 'L6'] },
//     { id: 'usasf-eliteclub-2024-senior', name: 'Senior', gte: 2005, gteJune1: true, lte: 2012, levels: ['L1', 'L2', 'L3', 'L4', 'L4.2', 'L5', 'L6'] },
//     { id: 'usasf-eliteclub-2024-senioropen', name: 'Senior Open', lte: 2007, lteMay31: true, levels: ['L1', 'L2', 'L3', 'L4', 'L4.2', 'L5', 'L6'] }
//   ],
//   'usasf-eliteint-2024': [
//     { id: 'usasf-eliteint-2024-u16', name: 'U16', gte: 2008, lte: 2013, levels: ['L1', 'L2', 'L3', 'L4'] },
//     { id: 'usasf-eliteint-2024-u18', name: 'U18', gte: 2006, lte: 2011, levels: ['L1', 'L2', 'L3', 'L4', 'L6'] },
//     { id: 'usasf-eliteint-2024-open', name: 'Open', lte: 2007, lteMay31: true, levels: ['L6', 'L7'] }
//   ],
//   'usasf-prep-2024': [
//     { id: 'usasf-prep-2024-tiny', name: 'Tiny', gte: 2017, lte: 2019, levels: ['L1'] },
//     { id: 'usasf-prep-2024-mini', name: 'Mini', gte: 2015, lte: 2018, levels: ['L1', 'L2'] },
//     { id: 'usasf-prep-2024-youth', name: 'Youth', gte: 2012, lte: 2017, levels: ['L1', 'L2', 'L3'] },
//     { id: 'usasf-prep-2024-junior', name: 'Junior', gte: 2009, lte: 2016, levels: ['L1', 'L2', 'L3'] },
//     { id: 'usasf-prep-2024-senior', name: 'Senior', gte: 2005, gteJune1: true, lte: 2013, levels: ['L1', 'L2', 'L3'] }
//   ],
//   'usasf-novice-2024': [
//     { id: 'usasf-novice-2024-tiny', name: 'Tiny', gte: 2017, lte: 2021, levels: ['L1'] },
//     { id: 'usasf-novice-2024-mini', name: 'Mini', gte: 2015, lte: 2018, levels: ['L1', 'L2'] },
//     { id: 'usasf-novice-2024-youth', name: 'Youth', gte: 2012, lte: 2017, levels: ['L1', 'L2', 'L3'] },
//     { id: 'usasf-novice-2024-junior', name: 'Junior', gte: 2009, lte: 2016, levels: ['L1', 'L2', 'L3'] },
//     { id: 'usasf-novice-2024-senior', name: 'Senior', gte: 2005, gteJune1: true, lte: 2013, levels: ['L1', 'L2', 'L3'] }
//   ],
//   'iasf-cheer-2024': [
//     { id: 'iasf-cheer-2024-u6', name: 'U6', gte: 2018, lte: 2020, levels: ['L1'] },
//     { id: 'iasf-cheer-2024-u8', name: 'U8', gte: 2016, lte: 2020, levels: ['L1', 'L2'] },
//     { id: 'iasf-cheer-2024-u12', name: 'U12', gte: 2012, lte: 2017, levels: ['L1', 'L2', 'L3', 'L4', 'L5'] },
//     { id: 'iasf-cheer-2024-u16', name: 'U16', gte: 2008, lte: 2013, levels: ['L1', 'L2', 'L3', 'L4', 'L5', 'L6'] },
//     { id: 'iasf-cheer-2024-u18', name: 'U18', gte: 2006, lte: 2011, levels: ['L1', 'L2', 'L3', 'L4', 'L5', 'L6'] },
//     { id: 'iasf-cheer-2024-open', name: 'Open', lte: 2009, levels: ['L1', 'L2', 'L3', 'L4', 'L5', 'L6'] },
//     { id: 'iasf-cheer-2024-openl7', name: 'Open L7', lte: 2008, levels: ['L7'] }
//   ],
//   'open-elite-2024': [
//     { id: 'open-elite-2024-tiny', name: 'Tiny', gte: 2017, lte: 2019, levels: ['L1'] },
//     { id: 'open-elite-2024-mini', name: 'Mini', gte: 2015, lte: 2019, levels: ['L1', 'L2'] },
//     { id: 'open-elite-2024-youth', name: 'Youth', gte: 2012, lte: 2019, levels: ['L1', 'L2', 'L3', 'L4', 'L5'] },
//     { id: 'open-elite-2024-junior', name: 'Junior', gte: 2008, lte: 2018, levels: ['L1', 'L2', 'L3', 'L4', 'L4.2', 'L5', 'L6'] },
//     { id: 'open-elite-2024-senior', name: 'Senior', gte: 2005, gteJune1: true, lte: 2012, levels: ['L1', 'L2', 'L3', 'L4', 'L4.2', 'L5', 'L6'] }
//   ],
//   'open-int-2024': [
//     { id: 'open-int-2024-u17', name: 'U17', gte: 2007, lte: 2015, levels: ['L1', 'L2', 'L3', 'L4'] },
//     { id: 'open-int-2024-u19', name: 'U19', gte: 2005, lte: 2011, levels: ['L1', 'L2', 'L3', 'L4'] },
//     { id: 'open-int-2024-open', name: 'Open', lte: 2010, levels: ['L4', 'L5', 'L6'] }
//   ],
//   'open-prep-2024': [
//     { id: 'open-prep-2024-tiny', name: 'Tiny', gte: 2017, lte: 2019, levels: ['L1'] },
//     { id: 'open-prep-2024-mini', name: 'Mini', gte: 2015, lte: 2019, levels: ['L1', 'L2'] },
//     { id: 'open-prep-2024-youth', name: 'Youth', gte: 2012, lte: 2019, levels: ['L1', 'L2', 'L3'] },
//     { id: 'open-prep-2024-junior', name: 'Junior', gte: 2008, lte: 2018, levels: ['L1', 'L2', 'L3'] },
//     { id: 'open-prep-2024-senior', name: 'Senior', gte: 2005, gteJune1: true, lte: 2014, levels: ['L1', 'L2', 'L3'] }
//   ],
//   'open-novice-2024': [
//     { id: 'usasf-novice-2024-tiny', name: 'Tiny', gte: 2017, lte: 2021, levels: ['L1'] },
//     { id: 'usasf-novice-2024-mini', name: 'Mini', gte: 2015, lte: 2019, levels: ['L1', 'L2'] },
//     { id: 'usasf-novice-2024-youth', name: 'Youth', gte: 2012, lte: 2019, levels: ['L1', 'L2', 'L3'] },
//     { id: 'usasf-novice-2024-junior', name: 'Junior', gte: 2008, lte: 2018, levels: ['L1', 'L2', 'L3'] },
//     { id: 'usasf-novice-2024-senior', name: 'Senior', gte: 2005, gteJune1: true, lte: 2014, levels: ['L1', 'L2', 'L3'] }
//   ]
// }

export function birthYearRequiresMidYearRange (birthYear: number): boolean {
  return data.birthYearRequiresMidYearRange.includes(birthYear)
//  return birthYear === 2005 || birthYear === 2007
}

export function levelsByGrid (gridId: AgeGridId): LevelId[] {
  const levels = new Set<LevelId>()
  for (const division of ageGridLevels[gridId]) {
    for (const level of division.levels) {
      levels.add(level)
    }
  }
  return Array.from(levels).sort()
}

export function ageGroupsByGrid (gridId: AgeGridId): AgeGroupOption[] {
  if (ageGridLevels == null) console.error('ageGridLevels is null')
  if (ageGridLevels[gridId] == null) console.error(`ageGridLevels[${gridId}] is null`)
  return ageGridLevels[gridId].map(({ id, name }) => ({ id, name }))
}

export function ageGroupOptionsFromBirthYear (gridId: AgeGridId, birthYear: number, lteMay31?: boolean, gteJune1?: boolean): AgeGroupOption[] {
  const result: AgeGroupOption[] = []

  for (const division of allAgeGridLevels[gridId]) {
    if ((division.gte == null || birthYear >= division.gte) && (division.lte == null || birthYear <= division.lte)) {
      if (division.lteMay31 === true && division.lte === birthYear && lteMay31 === false) {
        continue
      }
      if (division.gteJune1 === true && division.gte === birthYear && gteJune1 === false) {
        continue
      }
      result.push({ id: division.name, name: division.name })
    }
  }
  return result
}

export function ageGroupsFromBirthYear (gridId: AgeGridId, birthYear: number, lteMay31?: boolean, gteJune1?: boolean): AgeGroup[] {
  const result: AgeGroup[] = []

  for (const division of allAgeGridLevels[gridId]) {
    if ((division.gte == null || birthYear >= division.gte) && (division.lte == null || birthYear <= division.lte)) {
      if (division.lteMay31 === true && division.lte === birthYear && lteMay31 === false) {
        continue
      }
      if (division.gteJune1 === true && division.gte === birthYear && gteJune1 === false) {
        continue
      }
      result.push(division.name as AgeGroup)
    }
  }
  return result
}

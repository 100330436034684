export interface IContinuationResult<T> {
  items: T[]
  count: number
  continuationKey?: string
}

export interface TenantSettings {
  skills?: Record<TryoutType, Record<SkillLevel, TryoutSkill[]>>
}

export enum TenantStatus {
  Onboarding = 'onboarding',
  Active = 'active',
  Expired = 'expired'
}

export enum TenantPlan {
  New = 'new',
  Trial = 'trial',
  Starter = 'starter',
  Premium = 'premium',
  Unlimited = 'unlimited',
}

export interface TenantPlanInfo {
  id: TenantPlan
  name: string
  maxAthletes?: number
  maxTeams?: number
  maxSeasons?: number
  maxTryouts?: number
  maxVideos?: number
}

export const trialDays = 7

export const tenantPlans: Record<TenantPlan, TenantPlanInfo> = {
  [TenantPlan.New]: {
    id: TenantPlan.New,
    name: 'New'
  },
  [TenantPlan.Trial]: {
    id: TenantPlan.Trial,
    name: 'Trial',
    maxAthletes: 5,
    maxTeams: 1,
    maxSeasons: 1,
    maxTryouts: 3,
    maxVideos: 1
  },
  [TenantPlan.Starter]: {
    id: TenantPlan.Starter,
    name: 'Starter',
    maxAthletes: 99,
    maxTeams: 10,
    maxSeasons: 5,
    maxTryouts: 5,
    maxVideos: 1
  },
  [TenantPlan.Premium]: {
    id: TenantPlan.Premium,
    name: 'Premium',
    maxAthletes: 149,
    maxTeams: 50,
    maxSeasons: 10,
    maxTryouts: 10,
    maxVideos: 3
  },
  [TenantPlan.Unlimited]: {
    id: TenantPlan.Unlimited,
    name: 'Unlimited',
    // maxAthletes: 1000,
    // maxTeams: 100,
    // maxSeasons: 20,
    // maxTryouts: 200,
    maxVideos: 100
  }
}

export interface ITenant {
  id: string
  name: string
  status: TenantStatus
  plan: TenantPlan
  expiration?: Date
  logo?: string
  logoBackground?: string
  domain?: string
  affiliate?: string
  timeZone?: string
  lastUpdated?: Date
  lastPayment?: Date
  lastPaymentAmount?: number
  settings?: TenantSettings
  deleted?: boolean
  createdAt: Date
  updatedAt: Date
}
export type ITenantInput = Omit<ITenant, 'id' | 'lastPayment' | 'lastPaymentAmount' | 'createdAt' | 'updatedAt'>
export const tenantInputKeys = ['name', 'logo', 'settings']

export interface OauthToken {
  token?: string
  scopes?: Record<string, { token: string, expiration?: number, context?: unknown }>
}

export interface AuthResult {
  ExpiresIn?: number
  AccessToken?: string
  IdToken?: string
  RefreshToken?: string
  RefreshExpiresAt?: number
  Tenant?: string
  Tenants?: ITenant[]
  WebVersion?: string
}

export const protectedTenantProperties = ['lastPayment', 'lastPaymentAmount']

export interface ITenantCheckoutSession {
  sessionId?: string
  clientSecret?: string
  url?: string
}

export interface IProfile {
  email: string
  name: string
  firstName: string
  lastName: string
  picture?: string
}

export interface IAccount extends IProfile {
  id: string
  email: string
  name: string
  firstName: string
  lastName: string
  picture?: string
  lastActive?: Date
  affiliate?: string
  roles?: AccountRole[]
  oauthTokens?: Record<string, OauthToken>
  profile?: Record<string, unknown>
  stripeCustomerId?: string
  active: boolean
  deleted?: boolean
  createdAt: Date
  updatedAt: Date
}
export type IAccountInput = Omit<IAccount, 'id' | 'createdAt' | 'updatedAt'>
export type IMyAccountInput = Omit<IAccountInput, 'roles' | 'oauthTokens' | 'active' | 'profile' | 'stripeCustomerId'>

export interface IStaffAccount {
  id: string
  email: string
  picture?: string
  name: string
  roles: Array<AccountRole | TenantRole>
}
export type IStaffAccountInput = Omit<IStaffAccount, 'id'>

export interface IAccountTenant {
  accountId: string
  tenantId: string
  roles?: TenantRole[]
  profile?: object
}

export interface IAccountLogin {
  id: string
  provider: string
  email: string
}

export type IAccountTenantInput = IAccountTenant & { accountId: string, tenantId: string }

export interface IAccountWithAccountTenant extends IAccount {
  accountTenant?: IAccountTenant
}

export interface ITenantWithAccountTenant extends ITenant {
  accountTenant?: IAccountTenant
}

export type ITenantWithOwner = ITenant & { owner?: IAccount }

export const protectedAccountProperties = ['oauthTokens', 'roles', 'stripeCustomerId']

export enum AccountRole {
  Administrator = 'admin',
  SuperAdministrator = 'super'
}

export enum TenantRole {
  Owner = 'owner',
  Manager = 'manager'
}

export interface ISeason {
  tenantId: string
  id: string
  name: string
  deleted?: boolean
  createdAt: Date
  updatedAt: Date
}
export type ISeasonInput = Omit<ISeason, 'tenantId' | 'id' | 'createdAt' | 'updatedAt'>
export type ISeasonUpdate = Omit<ISeason, 'tenantId' | 'createdAt' | 'updatedAt'>

export interface ITeam {
  tenantId: string // PK
  seasonId: string // composite SK with id
  id: string
  accountId?: string
  name: string
  order: number
  deleted?: boolean
  createdAt: Date
  updatedAt: Date
}
export type ITeamInput = Omit<ITeam, 'tenantId' | 'seasonId' | 'id' | 'order' | 'createdAt' | 'updatedAt'> & { order?: number }
export const teamInputKeys = ['name', 'order']

export interface IAthlete {
  tenantId: string
  id: string
  customerId?: string
  firstName: string
  lastName: string
  birthYear: number
  lteMay31?: boolean
  gteJune1?: boolean
  headshotUrl?: string
  deleted?: boolean
  createdAt: Date
  updatedAt: Date
}
export type IAthleteInput = Omit<IAthlete, 'tenantId' | 'id' | 'createdAt' | 'updatedAt'>
export const athleteInputKeys = ['customerId', 'firstName', 'lastName', 'birthYear', 'lteMay31', 'gteJune1', 'headshotUrl']

export enum AgeGroup {
  Tiny = 'Tiny',
  Mini = 'Mini',
  Youth = 'Youth',
  Junior = 'Junior',
  Senior = 'Senior',
  Open = 'Open',
}

export function ageGroupsFromBirthYear (birthYear: number): AgeGroup[] {
  const result: AgeGroup[] = []
  if (birthYear >= 2017 && birthYear <= 2019) {
    result.push(AgeGroup.Tiny)
  }
  if (birthYear >= 2015 && birthYear <= 2018) {
    result.push(AgeGroup.Mini)
  }
  if (birthYear >= 2012 && birthYear <= 2017) {
    result.push(AgeGroup.Youth)
  }
  if (birthYear >= 2009 && birthYear <= 2016) {
    result.push(AgeGroup.Junior)
  }
  if (birthYear <= 2012) {
    result.push(AgeGroup.Senior)
  }
  return result
}

export enum Position {
  Flyer = 'Flyer',
  Base = 'Base',
  Backspot = 'Backspot',
  Tumbler = 'Tumbler',
}

export interface ITryoutRecording {
  id: string
  thumbnailUrl?: string
  videoUrl?: string
}

export enum TryoutType {
  Uncategorized = 'Uncategorized',
  Jump = 'Jump',
  StandingTumbling = 'Standing Tumbling',
  RunningTumbling = 'Running Tumbling',
}

export enum TryoutSkill {
  Jump = 'Jump',

  Cartwheel = 'Cartwheel',
  BWO = 'BW',
  BW_BHS = 'BW BHS',
  BHS_BW = 'BHS step out BW BHS',
  BHS_BHS_BHS = 'BHS BHS BHS',
  BHS_BW_BHS_BHS = 'BHS step out BW BHS BHS',
  BHS_BHS_Tuck = 'BHS BHS Tuck',
  Jump_BHS_Tuck = 'Jump to BHS to Tuck',
  BHS_BHS_Layout = 'BHS BHS Layout',
  BHS_Layout = 'BHS Layout',
  BHS_BHS_Full = 'BHS BHS Full',
  BHS_Full = 'BHS Full',

  RO = 'RO',
  FW = 'FW',
  RO_BHS = 'RO BHS Step out',
  FW_RO_BHS = 'FW RO BHS',
  RO_BHS_Tuck = 'RO BHS Tuck',
  FW_RO_BHS_Tuck = 'FW RO BHS Tuck',
  RO_BHS_Layout = 'RO BHS Layout',
  PF_Whip_Layout = 'PF or Whip through to Layout',
  RO_BHS_Full = 'RO BHS Full',
  PF_Whip_Full = 'PF or Whip through to Full',
  Arabian_FHS_Tuck = 'Arabian or FHS Front Tuck through to Full',
  Double_Full = 'Double Full'
}

export enum SkillLevel {
  L1 = 'L1',
  L2 = 'L2',
  L3 = 'L3',
  L4 = 'L4',
  L4_2 = 'L4.2',
  L5 = 'L5',
  L6 = 'L6',
  L7 = 'L7'
}

export const tryoutTypes: TryoutType[] = [
  TryoutType.Jump,
  TryoutType.StandingTumbling,
  TryoutType.RunningTumbling
]

export const tryoutTypeValues: Partial<Record<TryoutType, string>> = {
  [TryoutType.Jump]: TryoutType.Jump,
  [TryoutType.StandingTumbling]: TryoutType.StandingTumbling,
  [TryoutType.RunningTumbling]: TryoutType.RunningTumbling
}

export const skillLevels: SkillLevel[] = [
  SkillLevel.L1,
  SkillLevel.L2,
  SkillLevel.L3,
  SkillLevel.L4,
  SkillLevel.L4_2,
  SkillLevel.L5,
  SkillLevel.L6,
  SkillLevel.L7
]

export const skillLevelValues: Partial<Record<SkillLevel, string>> = {
  [SkillLevel.L1]: SkillLevel.L1,
  [SkillLevel.L2]: SkillLevel.L2,
  [SkillLevel.L3]: SkillLevel.L3,
  [SkillLevel.L4]: SkillLevel.L4,
  [SkillLevel.L4_2]: SkillLevel.L4_2,
  [SkillLevel.L5]: SkillLevel.L5,
  [SkillLevel.L6]: SkillLevel.L6,
  [SkillLevel.L7]: SkillLevel.L7
}

export const skillsByTypeLevel: Partial<Record<TryoutType, Record<SkillLevel, TryoutSkill[]>>> = {
  [TryoutType.Jump]: {
    [SkillLevel.L1]: [TryoutSkill.Jump],
    [SkillLevel.L2]: [TryoutSkill.Jump],
    [SkillLevel.L3]: [TryoutSkill.Jump],
    [SkillLevel.L4]: [TryoutSkill.Jump],
    [SkillLevel.L4_2]: [TryoutSkill.Jump],
    [SkillLevel.L5]: [TryoutSkill.Jump],
    [SkillLevel.L6]: [TryoutSkill.Jump],
    [SkillLevel.L7]: [TryoutSkill.Jump]
  },
  [TryoutType.StandingTumbling]: {
    [SkillLevel.L1]: [TryoutSkill.Cartwheel, TryoutSkill.BWO],
    [SkillLevel.L2]: [TryoutSkill.BW_BHS, TryoutSkill.BHS_BW],
    [SkillLevel.L3]: [TryoutSkill.BHS_BHS_BHS, TryoutSkill.BHS_BW_BHS_BHS],
    [SkillLevel.L4]: [TryoutSkill.BHS_BHS_Tuck, TryoutSkill.Jump_BHS_Tuck],
    [SkillLevel.L4_2]: [TryoutSkill.BHS_BHS_Tuck, TryoutSkill.Jump_BHS_Tuck],
    [SkillLevel.L5]: [TryoutSkill.BHS_BHS_Layout, TryoutSkill.BHS_Layout],
    [SkillLevel.L6]: [TryoutSkill.BHS_BHS_Full, TryoutSkill.BHS_Full],
    [SkillLevel.L7]: [TryoutSkill.BHS_BHS_Full, TryoutSkill.BHS_Full]
  },
  [TryoutType.RunningTumbling]: {
    [SkillLevel.L1]: [TryoutSkill.RO, TryoutSkill.FW],
    [SkillLevel.L2]: [TryoutSkill.RO_BHS, TryoutSkill.FW_RO_BHS],
    [SkillLevel.L3]: [TryoutSkill.RO_BHS_Tuck, TryoutSkill.FW_RO_BHS_Tuck],
    [SkillLevel.L4]: [TryoutSkill.RO_BHS_Layout, TryoutSkill.PF_Whip_Layout],
    [SkillLevel.L4_2]: [TryoutSkill.RO_BHS_Layout, TryoutSkill.PF_Whip_Layout],
    [SkillLevel.L5]: [TryoutSkill.RO_BHS_Full, TryoutSkill.PF_Whip_Full],
    [SkillLevel.L6]: [TryoutSkill.Arabian_FHS_Tuck, TryoutSkill.Double_Full],
    [SkillLevel.L7]: [TryoutSkill.Arabian_FHS_Tuck, TryoutSkill.Double_Full]
  }
}

export const skillsByTypeLevelValuesXX: Partial<Record<TryoutType, Record<SkillLevel, Partial<Record<TryoutSkill, string>>>>> = {
  [TryoutType.Jump]: {
    [SkillLevel.L1]: {
      [TryoutSkill.Jump]: TryoutSkill.Jump
    },
    [SkillLevel.L2]: {
      [TryoutSkill.Jump]: TryoutSkill.Jump
    },
    [SkillLevel.L3]: {
      [TryoutSkill.Jump]: TryoutSkill.Jump
    },
    [SkillLevel.L4]: {
      [TryoutSkill.Jump]: TryoutSkill.Jump
    },
    [SkillLevel.L4_2]: {
      [TryoutSkill.Jump]: TryoutSkill.Jump
    },
    [SkillLevel.L5]: {
      [TryoutSkill.Jump]: TryoutSkill.Jump
    },
    [SkillLevel.L6]: {
      [TryoutSkill.Jump]: TryoutSkill.Jump
    },
    [SkillLevel.L7]: {
      [TryoutSkill.Jump]: TryoutSkill.Jump
    }
  },
  [TryoutType.StandingTumbling]: {
    [SkillLevel.L1]: {
      [TryoutSkill.Cartwheel]: TryoutSkill.Cartwheel,
      [TryoutSkill.BWO]: TryoutSkill.BWO
    },
    [SkillLevel.L2]: {
      [TryoutSkill.BW_BHS]: TryoutSkill.BW_BHS,
      [TryoutSkill.BHS_BW]: TryoutSkill.BHS_BW
    },
    [SkillLevel.L3]: {
      [TryoutSkill.BHS_BHS_BHS]: TryoutSkill.BHS_BHS_BHS,
      [TryoutSkill.BHS_BW_BHS_BHS]: TryoutSkill.BHS_BW_BHS_BHS
    },
    [SkillLevel.L4]: {
      [TryoutSkill.BHS_BHS_Tuck]: TryoutSkill.BHS_BHS_Tuck,
      [TryoutSkill.Jump_BHS_Tuck]: TryoutSkill.Jump_BHS_Tuck
    },
    [SkillLevel.L4_2]: {
      [TryoutSkill.BHS_BHS_Tuck]: TryoutSkill.BHS_BHS_Tuck,
      [TryoutSkill.Jump_BHS_Tuck]: TryoutSkill.Jump_BHS_Tuck
    },
    [SkillLevel.L5]: {
      [TryoutSkill.BHS_BHS_Layout]: TryoutSkill.BHS_BHS_Layout,
      [TryoutSkill.BHS_Layout]: TryoutSkill.BHS_Layout
    },
    [SkillLevel.L6]: {
      [TryoutSkill.BHS_BHS_Full]: TryoutSkill.BHS_BHS_Full,
      [TryoutSkill.BHS_Full]: TryoutSkill.BHS_Full
    },
    [SkillLevel.L7]: {
      [TryoutSkill.BHS_BHS_Full]: TryoutSkill.BHS_BHS_Full,
      [TryoutSkill.BHS_Full]: TryoutSkill.BHS_Full
    }
  },
  [TryoutType.RunningTumbling]: {
    [SkillLevel.L1]: {
      [TryoutSkill.RO]: TryoutSkill.RO,
      [TryoutSkill.FW]: TryoutSkill.FW
    },
    [SkillLevel.L2]: {
      [TryoutSkill.RO_BHS]: TryoutSkill.RO_BHS,
      [TryoutSkill.FW_RO_BHS]: TryoutSkill.FW_RO_BHS
    },
    [SkillLevel.L3]: {
      [TryoutSkill.RO_BHS_Tuck]: TryoutSkill.RO_BHS_Tuck,
      [TryoutSkill.FW_RO_BHS_Tuck]: TryoutSkill.FW_RO_BHS_Tuck
    },
    [SkillLevel.L4]: {
      [TryoutSkill.RO_BHS_Layout]: TryoutSkill.RO_BHS_Layout,
      [TryoutSkill.PF_Whip_Layout]: TryoutSkill.PF_Whip_Layout
    },
    [SkillLevel.L4_2]: {
      [TryoutSkill.RO_BHS_Layout]: TryoutSkill.RO_BHS_Layout,
      [TryoutSkill.PF_Whip_Layout]: TryoutSkill.PF_Whip_Layout
    },
    [SkillLevel.L5]: {
      [TryoutSkill.RO_BHS_Full]: TryoutSkill.RO_BHS_Full,
      [TryoutSkill.PF_Whip_Full]: TryoutSkill.PF_Whip_Full
    },
    [SkillLevel.L6]: {
      [TryoutSkill.Arabian_FHS_Tuck]: TryoutSkill.Arabian_FHS_Tuck,
      [TryoutSkill.Double_Full]: TryoutSkill.Double_Full
    },
    [SkillLevel.L7]: {
      [TryoutSkill.Arabian_FHS_Tuck]: TryoutSkill.Arabian_FHS_Tuck,
      [TryoutSkill.Double_Full]: TryoutSkill.Double_Full
    }
  }
}

export interface ITryoutEmbedded {
  type?: TryoutType
  skill?: TryoutSkill
  level?: SkillLevel
  rating?: number
  notes?: string
  recordings?: ITryoutRecording[]
}
export type ITryoutInput = Omit<ITryoutEmbedded, 'recordings'>

export const tryoutInputKeys = ['type', 'skill', 'level', 'rating', 'notes']

export interface ITryout extends ITryoutEmbedded {
  tenantId: string
  seasonId: string // composite SK with athleteId & id
  athleteId: string // composite SK with id
  id: string
  deleted?: boolean
  createdAt: Date
  updatedAt: Date
}
export const tryoutNonEmbeddedKeys = ['tenantId', 'seasonId', 'athleteId', 'createdAt', 'updatedAt']

export interface ITryoutMediaInput {
  mimeType: string
}

export interface ITryoutMedia {
  uploadUrl: string
  downloadUrl?: string
  mimeType: string
  id: string
}

export interface ISeasonAthlete {
  tenantId: string // PK
  seasonId: string // composite SK with athleteId
  athleteId: string
  ageGroups?: AgeGroup[]
  levels?: SkillLevel[]
  positions?: Position[]
  positionsRequested?: Position[]
  positionNotes?: string
  levelsRequested?: SkillLevel[]
  levelNotes?: string
  otherNotes?: string
  totalRating?: number
  deleted?: boolean
  createdAt: Date
  updatedAt: Date
}
export const seasonAthleteInputKeys = ['ageGroups', 'levels', 'positions', 'positionsRequested', 'positionNotes', 'levelsRequested', 'levelNotes', 'otherNotes', 'totalRating']

export interface ISeasonAthleteWithAthlete extends IAthlete {
  ageGroups?: AgeGroup[]
  levels?: SkillLevel[]
  positions?: Position[]
  positionsRequested?: Position[]
  positionNotes?: string
  levelsRequested?: SkillLevel[]
  levelNotes?: string
  otherNotes?: string
  totalRating?: number
  tryouts?: ITryoutEmbedded[]
}
export type ISeasonAthleteWithAthleteInput = Omit<ISeasonAthleteWithAthlete, 'tenantId' | 'seasonId' | 'athleteId' | 'id' | 'createdAt' | 'updatedAt'>

export interface ISeasonAthleteWithAthleteAndTryouts extends IAthlete {
  ageGroups?: AgeGroup[]
  levels?: SkillLevel[]
  positions?: Position[]
  positionsRequested?: Position[]
  positionNotes?: string
  levelsRequested?: SkillLevel[]
  levelNotes?: string
  otherNotes?: string
  totalRating?: number
  tryouts?: ITryoutEmbedded[]
}

export interface ITeamAthlete {
  tenantId: string // PK
  seasonId: string // composite SK with teamId and athleteId
  teamId: string
  athleteId: string
  crossover?: boolean
  position?: Position
  flag?: boolean
  createdAt: Date
  updatedAt: Date
}
export type ITeamAthleteInput = Omit<ITeamAthlete, 'tenantId' | 'seasonId' | 'teamId' | 'athleteId' | 'createdAt' | 'updatedAt'>

export interface IAsset {
  tenantId: string
  id: string
  type: string
  athleteId?: string
  seasonId?: string
  tryoutId?: string
  mimeType: string
  uploadedKey: string
  transcodedKey?: string
  thumbnailKey?: string
  createdAt: Date
  updatedAt: Date
}
export type IAssetInput = Omit<IAsset, 'tenantId' | 'id' | 'createdAt' | 'updatedAt'>

export enum GoogleSheetImportError {
  InvalidUrl = 'InvalidUrl',
  InvalidSheet = 'InvalidSheet',
  InvalidHeaders = 'InvalidHeaders',
  NoAccess = 'NoAccess',
  NoAthletes = 'NoAthletes',
  NoHeaders = 'NoHeaders',
  Internal = 'Internal',
  Success = 'Success'
}

export interface IGoogleSheetImportPreviewError {
  error: GoogleSheetImportError
}

export interface IGoogleSheetImportPreview {
  id: string
  title: string
  sheetNames: string[]
  athletes: ISeasonAthleteWithAthlete[]
  headers: string[]
  columnTypes: Array<string | number | boolean>
  data: Array<Record<string, string | number | boolean>>
}

export enum SubscriptionStatus {
  Active = 'active',
  Refunded = 'refunded',
  Canceled = 'canceled',
  PastDue = 'past_due',
  Unpaid = 'unpaid',
  Trial = 'trial'
}

export interface ISubscription {
  id: string
  purchasingAccountId?: string
  status: SubscriptionStatus
  plan: TenantPlan
  lastPurchase?: Date
  lastPurchaseAmount?: number
  affiliate?: string
  createdAt: Date
  updatedAt: Date
}

export interface ISubscriptionTransaction {
  subscriptionId: string
  id: string
  transactionType: string
  stripeId: string
  transactionDate: Date
  transactionAmount?: number
  message: string
  data?: Record<string, unknown>
  createdAt: Date
  updatedAt: Date
}

export interface ISubscriptionWithTransactions extends ISubscription {
  transactions: ISubscriptionTransaction[]
}

export interface ITenantSubscription {
  tenantId: string
  subscriptionId: string
  createdAt: Date
  updatedAt: Date
}

export interface IAccountSubscription {
  accountId: string
  subscriptionId: string
  createdAt: Date
  updatedAt: Date
}

// https://tailwindui.com/components/application-ui/application-shells/multi-column
import { Fragment, type PropsWithChildren, type ReactElement, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
  Bars3Icon,
  QuestionMarkCircleIcon,
  UserIcon,
  XMarkIcon
} from '@heroicons/react/24/outline'
import { type IconType, classes, DropDownMenu, type DropDownMenuItem } from '@andyneville/tailwind-react'
import { Link, useLocation } from 'react-router-dom'
import { type ISeasonAthleteWithAthlete } from '../../../api/api'
import AthleteSearchBox from '../components/AthleteSearchBox'

export interface MenuItemProps {
  name: string
  route: string
  Icon: IconType
}

interface MultiColumnLayoutProps {
  navigation: MenuItemProps[]
  adminNavigation?: MenuItemProps[]
  helpMenu?: DropDownMenuItem[]
  userMenu?: DropDownMenuItem[]
  logo: ReactElement
  athletes: ISeasonAthleteWithAthlete[]
  onSelect: (athlete: ISeasonAthleteWithAthlete) => void
  pictureUrl?: string | null
  orgLogoUrl?: string | null
  appName: string
  hFull?: boolean
  trialExpiration?: Date
  planExpiration?: Date
  hideMenu?: boolean
  shouldUpgrade?: boolean
  webVersion?: string
  signout: () => void
}

const debugResponsiveBreakPoints = import.meta.env.VITE_SHOW_RESPONSIVE_BREAKPOINTS === 'true'

export function MenuItem (props: MenuItemProps & { mobile?: boolean, onNav?: () => void }): ReactElement {
  const { route, Icon, name, mobile = false, onNav } = props
  const location = useLocation()
  const active = location.pathname === route
  return (
    <li>
      <Link
        to={route}
        className={classes(
          active
            ? 'bg-gray-800 dark:bg-dark-800 text-white'
            : 'text-gray-400 dark:text-dark-400 hover:text-white hover:bg-gray-800 hover:dark:bg-dark-800',
          mobile ? 'p-2' : 'p-3 ',
          'group flex gap-x-3 rounded-md text-sm leading-6 font-semibold'
        )}
        onClick={onNav}
      >
        <Icon
          className={classes(
            active ? 'text-white' : 'text-gray-400 dark:text-dark-400 hover:text-white hover:bg-gray-800',
            'h-6 w-6 shrink-0'
          )}
          aria-hidden="true"
        />
        <span
          className={classes(
            mobile ? '' : 'hidden xl:block'
          )}
        >
          {name}
        </span>
      </Link>
    </li>
  )
}

export default function MultiColumnLayout (props: PropsWithChildren<MultiColumnLayoutProps>): ReactElement {
  const { navigation, adminNavigation, athletes, onSelect, shouldUpgrade = false, webVersion = '', logo, appName, pictureUrl, helpMenu, userMenu, hideMenu = false, hFull = false, children, trialExpiration, planExpiration, orgLogoUrl } = props
  const [sidebarOpen, setSidebarOpen] = useState(false)

  useEffect(() => {
    document.body.classList.add('h-full')
    // document.body.parentElement?.classList.add('bg-gray-100')
    document.body.parentElement?.classList.add('h-full')
    return () => {
      document.body.classList.remove('h-full')
      // document.body.parentElement?.classList.remove('bg-gray-100')
      document.body.parentElement?.classList.remove('h-full')
    }
  })

  let expirationElement: ReactElement | undefined
  if (trialExpiration != null) {
    const trialDaysRemaining = trialExpiration != null ? Math.floor((trialExpiration.getTime() - Date.now()) / (1000 * 60 * 60 * 24)) : undefined
    if (trialDaysRemaining != null) {
      if (trialDaysRemaining <= 2) {
        expirationElement = <Link to="/plans" className="flex items-center px-2 text-sm text-white bg-red-600 rounded-full dark:text-white text-nowrap">{`Trial: ${trialDaysRemaining} day${trialDaysRemaining === 1 ? '' : 's'} left`}</Link>
      } else {
        expirationElement = <Link to="/plans" className="flex items-center px-2 text-sm text-white rounded-full bg-brand-600 dark:text-white text-nowrap">{`Trial: ${trialDaysRemaining} day${trialDaysRemaining === 1 ? '' : 's'} left`}</Link>
      }
    }
  } else if (planExpiration != null) {
    const planDaysRemaining = planExpiration != null ? Math.floor((planExpiration.getTime() - Date.now()) / (1000 * 60 * 60 * 24)) : undefined
    if (planDaysRemaining != null && planDaysRemaining <= 10) {
      if (planDaysRemaining <= 2) {
        expirationElement = <div className="flex items-center px-2 text-sm text-white bg-red-600 rounded-full dark:text-white text-nowrap">Plan: {planDaysRemaining} day{planDaysRemaining === 1 ? '' : 's'} left</div>
      } else {
        expirationElement = <div className="flex items-center px-2 text-sm text-white bg-gray-500 rounded-full dark:bg-dark-500 dark:text-white text-nowrap">Plan: {planDaysRemaining} day{planDaysRemaining === 1 ? '' : 's'} left</div>
      }
    }
  }

  return (
    <>
      <div className={classes(hFull ? 'h-full' : '')} data-version={webVersion}>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80 dark:bg-dark-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex flex-1 w-full mr-16 max-w-[12rem] mt-safe">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 flex justify-center w-16 pt-5 left-full">
                      <button type="button" className="-m-2.5 p-2.5" onClick={() => { setSidebarOpen(false) }}>
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon className="w-6 h-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Mobile slideout sidebar */}
                  <div className="flex flex-col px-6 pb-2 overflow-y-auto bg-gray-900 dark:bg-dark-900 grow gap-y-5 ring-1 ring-white/10">
                    <div className="flex items-center h-16 shrink-0">
                      {logo != null &&
                        logo
                      }
                      <span className="ml-4 text-lg text-white">{appName}</span>
                    </div>
                    <nav className="flex flex-col flex-0">
                      <ul role="list" className="flex-1 -mx-2 space-y-1">
                        {!hideMenu && navigation.map((item) => (
                          <MenuItem key={item.name} onNav={() => { setSidebarOpen(false) }} mobile { ...item } />
                        ))}
                      </ul>
                    </nav>
{adminNavigation != null &&
                      <nav className="flex flex-col flex-1 pt-4">
                        <div className='pt-12 text-xs text-gray-300 dark:text-dark-300'>Admin</div>
                        <ul role="list" className="flex-1 -mx-2 space-y-1">
                          {!hideMenu && adminNavigation.map((item) => (
                            <MenuItem key={item.name} onNav={() => { setSidebarOpen(false) }} mobile {...item} />
                          ))}
                        </ul>
                      </nav>
                    }
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Top safe area bar for wide screens */}
        <div className="sticky top-0 z-40 items-center hidden bg-gray-900 shadow-sm dark:bg-dark-900 pt-safe lg:block">
        </div>
        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:left-0 lg:z-50 lg:block lg:w-24 xl:w-[10rem] lg:overflow-y-auto lg:bg-dark-900 lg:pb-4">
          <div className="flex flex-col items-center h-full">
            <div className="flex items-center justify-center h-16 mt-safe-offset-4 shrink-0">
              {logo != null &&
                logo
              }
            </div>
            <div className="flex items-center justify-center text-center shrink-0">
              {appName != null &&
                <h1 className='p-0 m-0 -mt-2 text-sm leading-4 text-brand-400'>{appName}</h1>
              }
            </div>
            <nav className="flex-1 mt-8 grow">
              <ul role="list" className="flex flex-col items-center space-y-1 xl:items-start">
                {!hideMenu && navigation.map((item) => (
                  <MenuItem key={item.name} {...item} />
                ))}
              </ul>
{adminNavigation != null &&
              <>
                <div className='pt-12 text-xs text-center text-gray-300 dark:text-dark-300'>Admin</div>
                <ul role="list" className="flex flex-col items-center space-y-1 xl:items-start">
                  {!hideMenu && adminNavigation.map((item) => (
                    <MenuItem key={item.name} {...item} />
                  ))}
                </ul>
              </>
              }
            </nav>
          </div>
        </div>

        <div className="h-full lg:pl-24 xl:pl-[10rem]">
        <div className="sticky top-0 z-40 flex items-center px-4 pb-4 bg-gray-900 shadow-sm dark:bg-dark-900 pt-safe-offset-4 gap-x-6 sm:px-6">
          <button type="button" className="-m-2.5 p-2.5 text-gray-400 dark:text-dark-400 lg:hidden" onClick={() => { setSidebarOpen(true) }}>
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="w-6 h-6" aria-hidden="true" />
          </button>
            {/* Separator */}
            <div className="w-px h-6 bg-gray-900/10 dark:bg-gray-200/10 lg:hidden" aria-hidden="true" />

            <div className="flex flex-1 gap-x-4 lg:gap-x-6">
              {orgLogoUrl != null &&
                <div className="items-center hidden sm:flex">
                  <img className="object-cover bg-white rounded h-9 max-w-20" src={orgLogoUrl} alt="" />
                </div>
              }
              <form className="relative grid items-center w-full grid-cols-12 twc-form ">
                {athletes != null && athletes.length > 0 &&
                  <AthleteSearchBox athletes={athletes} onSelect={onSelect} />
                }
              </form>
              {expirationElement}

              {(userMenu != null || helpMenu != null) &&
                <div className="flex items-center gap-x-4 lg:gap-x-6">
                  {/* Separator */}
                  <div className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10 dark:lg:bg-gray-200/10" aria-hidden="true" />
                  {debugResponsiveBreakPoints &&
                    <>
                      <div className="flex sm:hidden h-6 w-6 rounded-full bg-brand-600 text-white justify-center text-sm items-center" aria-hidden="true">-</div>
                      <div className="hidden sm:flex md:hidden h-6 w-6 rounded-full bg-brand-600 text-white justify-center text-xs items-center" aria-hidden="true">SM</div>
                      <div className="hidden md:flex lg:hidden h-6 w-6 rounded-full bg-brand-600 text-white justify-center text-xs items-center" aria-hidden="true">MD</div>
                      <div className="hidden lg:flex xl:hidden h-6 w-6 rounded-full bg-brand-600 text-white justify-center text-sm items-center" aria-hidden="true">LG</div>
                      <div className="hidden xl:flex 2xl:hidden h-6 w-6 rounded-full bg-brand-600 text-white justify-center text-sm items-center" aria-hidden="true">XL</div>
                      <div className="hidden 2xl:flex h-6 w-6 rounded-full bg-brand-600 text-white justify-center text-xs items-center" aria-hidden="true">2XL</div>
                    </>
                  }
                  {helpMenu != null &&
                    <DropDownMenu
                      id='help-menu'
                      Icon={QuestionMarkCircleIcon}
                      items={helpMenu}
                      buttonClass={'text-gray-200 dark:text-dark-200 hover:text-white dark:hover:text-white'}
                      widthClass='w-48 right-0'
                    />
                  }
                  {userMenu != null &&
                    <DropDownMenu
                      id='user-menu'
                      imageUrl={pictureUrl ?? undefined}
                      Icon={pictureUrl == null ? UserIcon : undefined}
                      items={userMenu}
                      buttonClass={'text-gray-200 dark:text-dark-200 hover:text-white dark:hover:text-white'}
                      widthClass='w-32 right-0'
                    />
                  }
                </div>
              }
            </div>
          </div>

          <main className={classes('', hFull ? 'h-full' : '')}>
            {shouldUpgrade &&
              <div className="bg-yellow-100 dark:bg-yellow-900 text-yellow-800 dark:text-yellow-200 p-2 text-center">
                <a className="underline cursor-pointer" onClick={() => { location.reload() }}>Refresh</a> for the latest version.
              </div>
            }
          <div className={classes('px-4 py-10 sm:px-6 lg:px-8 lg:py-6', hFull ? 'h-full' : '')}>{children}</div>
        </main>
        </div>
      </div>
    </>
  )
}
